<template>
	<!-- Hilti -->
	<!-- 8.300 User/22 Länder Q1 2021; >20k in '21
										Responsibilities -->
	<ProjectBlock v-if="idVal == 1" :pId="1" :products="['sales', 'marketing', 'platform', 'tableau', 'maps']" :otherProducts="['SAP ERP S/4 HANA', 'SAP Analytics Cloud', 'Microsoft Azure', 'SAP IdM']">
		<template v-slot:logo>
			<img src="../assets/work_salesforce.svg" height="110" alt="Company Logo Salesforce" />
		</template>
		<template v-slot:company>
			Global Manufacturer in Construction, Energy and Manufacturing
		</template>
		<template v-slot:from>
			Apr 2019
		</template>
		<template v-slot:to>
			Today
		</template>
		<template v-slot:description>
			A digital transformation for Sales and Marketing. Reorganisation of global selling as well as customer and project management approach.
		</template>
		<template v-slot:responsibilities>
			<li>Lead Technical & Integration Architect responsible for global technical template</li>
			<li>Rollout Lead consultant</li>
			<li>Development Lead</li>
			<li>
				Supporting IT Operations
				<!--(eg LoginAs, Translations, Monitoring Territories)-->
			</li>
			<li>User access governance and permission management; also focused on legal requirements</li>
		</template>
		<template v-slot:achievements>
			<li>Described and implemented event-based SAP ERP integration approach</li>
			<li>Specified access model in complex business scenario</li>
			<!-- China/Russia -->
			<li>Defined and implemented DevOps approach and <TooltipAbbreviation :short="'CI/CD'" :long="'Continuous Integration and Deployment'" /> framework based on <TooltipAbbreviation :short="'SFDX'" :long="'Salesforce Developer Experience'" /></li>
			<li>Outlined and implemented org strategy</li>
			<li>Supported various Data Migrations from legacy systems</li>
			<li>Test automation vendor evaluation and framework set-up</li>
			<li>Rollout to dozens of countries and supported user enablement</li>
			<!-- <li>Resolved various critical situations and incidents</li> -->
			<!-- Sales Cloud, MC, S/4 Hana,BW/4Hana,AzureAD,SAP  IDM,Bamboo,Github,Jira,Confluence,Docke -->
		</template>
	</ProjectBlock>
	<!-- ABB -->
	<ProjectBlock v-if="idVal == 2" :pId="2" :products="['fsl', 'sales', 'pardot', 'platform', 'mulesoft']" :otherProducts="['SAP ERP S/4 HANA (CS, SD, MM)', 'Microsoft AD']">
		<template v-slot:logo>
			<img src="../assets/work_salesforce.svg" height="110" alt="Company Logo Salesforce" />
		</template>
		<template v-slot:company>
			Global Robotics, Industrial Automation and Electrification Technology Company
		</template>
		<template v-slot:from>
			May 2018
		</template>
		<template v-slot:to>
			Aug 2019
		</template>
		<template v-slot:description>
			Digitalization of global Field Service management with one of the world’s largest <TooltipAbbreviation :short="'FSL'" :long="'Field Service Lightning'" /> projects within a complex Salesforce implementation. Transition from legacy service management application to fully mobile service
			management.
		</template>
		<template v-slot:responsibilities>
			<li>Lead Technical Architect & Salesforce Integration Architect responsible for process and system architecture</li>
			<li>Deployment & release management</li>
			<li>User access governance and permission management</li>
		</template>
		<template v-slot:achievements>
			<li>Integrated SAP <TooltipAbbreviation :short="'ERP'" :long="'Enterprise Resource Planning'" /> Customer Service management and Material Master for product management as well as installed base for managing assets and tools</li>
			<li>Evaluated app vendors for managing operational field service activities offline</li>
			<li>Supported solution rollout</li>
			<li>Implemented <TooltipAbbreviation :short="'CLM'" :long="'Contract Lifecycle Management'" /> and extended general Case management</li>
			<li>Defined end-to-end flow from planning over skill-based dispatching, customer interaction and operational activities</li>
			<!-- Engineers, Dispatchers, Time management; Dispatching and scheduling of work orders; Skill-based work assignment -->
			<li>Provided process for risk assessments and troubleshooting guides</li>
			<li>Defined test cases and supported end-to-end tests</li>
			<li>Supported Pardot & <TooltipAbbreviation :short="'CRM'" :long="'Customer Relationship Management'" /> integration</li>
		</template>
	</ProjectBlock>
	<!-- DVAG/VVS -->
	<ProjectBlock v-if="idVal == 3" :pId="3" :products="['sales']">
		<template v-slot:logo>
			<img src="../assets/work_salesforce.svg" height="110" alt="Company Logo Salesforce" />
		</template>
		<template v-slot:company>
			Financial Services
		</template>
		<template v-slot:from>
			May 2018
		</template>
		<template v-slot:to>
			Jul 2018
		</template>
		<template v-slot:description>
			Introduction of a collaboration platform for client management and general reporting.
		</template>
		<template v-slot:responsibilities>
			<li>On-demand Technical Architect</li>
		</template>
		<template v-slot:achievements>
			<li>Defined data architecture for Account management</li>
			<li>Supported <TooltipAbbreviation :short="'IdM'" :long="'Identity Management'" /> and Microsoft Office integration</li>
			<li>Outlined data migration procedure</li>
			<li>Introduced the team to deployment management</li>
		</template>
	</ProjectBlock>
	<!-- E+H -->
	<ProjectBlock v-if="idVal == 4" :pId="4" :products="['sales', 'service', 'platform', 'community', 'fsl', 'heroku']" :otherProducts="['SAP ERP S/4 HANA', 'Microsoft Azure']">
		<template v-slot:logo>
			<img src="../assets/work_salesforce.svg" height="110" alt="Company Logo Salesforce" />
		</template>
		<template v-slot:company>
			Global Instrumentation and Automation Supplier
		</template>
		<template v-slot:from>
			Dec 2017
		</template>
		<template v-slot:to>
			May 2018
		</template>
		<template v-slot:description>
			Introduction of global service management on Salesforce based on knowledge-centered support approach to simplify support and allow group-wide collaboration as part of the company's digitalization strategy. Implementation of Customer Community for global Case management and self-service.
		</template>
		<template v-slot:responsibilities>
			<li>Lead Technical & Integration Architect</li>
			<li>Development Lead</li>
			<li>Deployment management</li>
			<li>Security & user access governance</li>
		</template>
		<template v-slot:achievements>
			<li>Designed system landscape and release management process</li>
			<li>Defined Identity Management integration</li>
			<li>Supported Lightning Migration</li>
			<li>Migrated legacy Knowledge Base to Salesforce</li>
			<li>Evaluated architecture of <TooltipAbbreviation :short="'IIoT'" :long="'Industrial Internet of Things'" /> platform with Heroku</li>
			<li>Implemented Customer Community with Case management and Knowledge access; built custom registration process and implemented design based on brand identity</li>
			<li>Developed device certification process for service technicians based on complex <TooltipAbbreviation :short="'XML'" :long="'Extensible Markup Language'" />-based information</li>
			<li>Defined new Service management process on Salesforce; tight collaboration with UI/UX team</li>
		</template>
	</ProjectBlock>
	<!-- KUKA -->
	<ProjectBlock
		v-if="idVal == 5"
		:pId="5"
		:products="['sales', 'service', 'marketing', 'platform', 'fsl', 'tableau', 'community', 'iot', 'data']"
		:otherProducts="['SAP ERP S/4 HANA', 'SAP BW', 'SAP CRM 7', 'SAP Business ByDesign', 'Informatica PowerCenter', 'Software AG MDM', 'Microsoft AD', 'Apttus CPQ & e-Commerce']"
	>
		<template v-slot:logo>
			<img src="../assets/work_salesforce.svg" height="110" alt="Company Logo Salesforce" />
		</template>
		<template v-slot:company>
			Global Automation Manufacturer
		</template>
		<template v-slot:from>
			Sep 2016
		</template>
		<template v-slot:to>
			Dec 2017
		</template>
		<template v-slot:description>
			A digital transformation across the company to deliver a uniform customer view - from 25 to 1 <TooltipAbbreviation :short="'CRM'" :long="'Customer Relationship Management'" /> system. Integration of Marketing, Sales, and Service into one comprehensive and customer-centric platform for
			digital collaboration.
			<!-- globalisation and digitisation have increased the need for a standardised customer view. The challenges: To bring different areas of the Group under one roof from the customer’s perspective and reduce complexity. From the outset, a key element in managing
											these challenges was to integrate Marketing, Sales, and Service on one comprehensive platform. customer-centric, integrated organisation into a reality. There were around 25 different CRM systems worldwide to be standardised. Not only that, links had to be
											forged between Sales, Marketing, Service, and Product Development to facilitate internal collaboration. A key factor for successful market penetration, optimal customer service, and increasing customer value for KUKA is an understanding of the individual
											needs and expectations of its potential and existing customers. Holistic CRM is fundamental to an integrated relationship management approach An integral part of the group-wide standardisation of processes and systems was the OneCRM project, which was to
											provide a standard, 360-degree view of all customer data worldwide for all customer functions With Service Cloud, all service employees worldwide work directly in the one system; every service case and conversation takes place directly on the Object,
											giving Service and Sales real-time information about all ongoing processes with a specific customer Field Service Lightning simplifies case management and dispatcher scheduling, and provides a mobile solution for field technicians Community Cloud groups
											together the KUKA Group’s digital services on the one hand, while on the other hand forges connections between IT, Development, and Digital Services, as well as Sales and Customer service A Digital Transformation across the company. Reorganisation of
											global Opportunity selling for 20.000 employees. Managing Salesforce Architecture and integration with SAP ERP. Project Management, implementation, planning and development. Strategy decisions. Leading the Technical Architecture during project engagements. -->
			<!-- Agile team/Scrum -->
		</template>
		<template v-slot:responsibilities>
			<li>Technical Architect leading any Salesforce-related architecture and general enterprise architecture topics</li>
			<li>
				Data & Enterprise Integration Architect leading multiple integrations and migrations (SAP <TooltipAbbreviation :short="'ERP'" :long="'Enterprise Resource Planning'" />, SAP BusinessOne, SAP <TooltipAbbreviation :short="'BW'" :long="'Business Warehouse'" />, Sitecore, BMC Remedyforce,
				<TooltipAbbreviation :short="'PIM'" :long="'Product Information Management'" /> system, <TooltipAbbreviation :short="'LMS'" :long="'Learning Management System'" /> and others) to enable a holistic <TooltipAbbreviation :short="'CRM'" :long="'Customer Relationship Management'" />
			</li>
			<li>Development Lead</li>
			<li>Program Architect stand-in</li>
		</template>
		<!-- Program Architect vertretung! -->
		<template v-slot:achievements>
			<li>Consolidated 25 different <TooltipAbbreviation :short="'CRM'" :long="'Customer Relationship Management'" /> systems into one</li>
			<!-- Product hierarchy -->
			<li>
				Integrated existing pricing and product management solution (<TooltipAbbreviation :short="'PIM'" :long="'Product Information Management'" />) with Apttus <TooltipAbbreviation :short="'CPQ'" :long="'Configure, Price, Quote'" /> & e-Commerce (planning, custom integration, transition
				planning, testing)
			</li>
			<li>Planned and implemented Lightning Experience Switch and established architecture as Development Lead</li>
			<li>Evaluated and integrated new enterprise <TooltipAbbreviation :short="'MDM'" :long="'Master Data Management'" /> solution</li>
			<li>Planned and orchestrated data migration from multiple legacy CRM systems</li>
			<li>Planned and implemented SAP <TooltipAbbreviation :short="'ERP'" :long="'Enterprise Resource Planning'" /> migration and integration (customer, installed base management) as well as other <TooltipAbbreviation :short="'ERP'" :long="'Enterprise Resource Planning'" /> systems</li>
			<li>Implemented Partner Community</li>
			<li>Evaluated backup & recovery and data management solutions</li>
			<li>Implemented identity management incl. external user provisioning</li>
			<li>Planned and implemented data governance & stewardship framework incl. Data.com</li>
			<li>Defined test cases and supported end-to-end tests</li>
			<li>Implemented <TooltipAbbreviation :short="'CI/CD'" :long="'Continuous Integration and Deployment'" /> process framework based on Copado</li>
			<li>Developed various Apex/Visualforce/Lightning solutions</li>
		</template>
	</ProjectBlock>
	<ProjectBlock v-if="idVal == 6" :pId="6" :products="['platform', 'sales', 'service', 'marketing']" :otherProducts="['SAP ERP ECC 6.0 (CS, SD, MM, GTS)', 'SAP BW', 'SAP HR', 'Microsoft AD', 'Talend Data Integration']">
		<template v-slot:logo>
			<img src="../assets/work_schaeffler.svg" width="240" alt="Company Logo Schaeffler" />
		</template>
		<template v-slot:company>
			Global Automotive, Industrial and Aerospace Supplier
		</template>
		<template v-slot:from>
			May 2013
		</template>
		<template v-slot:to>
			Jun 2016
		</template>
		<template v-slot:description>
			Implementation, rollout and operations in the Automotive Industrial, Aftermarket and OEM sector to provide a global multi-org solution for customer management, service, marketing and market analysis across the company.
		</template>
		<template v-slot:responsibilities>
			<!-- , evaluation and concepts for and implementation of business processes -->
			<!-- <li>Analysing performance- or security-critical processes as well as legal requirements.</li> -->
			<!-- D/Q/Integration/P * 3) -->
			<!-- <li>Complex mobile app development SSO/OAuth investigation</li> -->

			<!-- Key -->
			<!--  (three separate orgs with shared data and very complex developments) -->
			<li>
				Salesforce Lead Solution & Integration Architect (<!--CRM architecture & system landscape management in -->multi-org implementation with 3&ensp;500+ users
				<!--in three business units-->)
			</li>
			<li>
				Salesforce Lead Developer (<!--Apex, Visualforce; -->
				100+ components created)
			</li>
			<li>Salesforce customization & administration; task assignments to <TooltipAbbreviation :short="'CRM'" :long="'Customer Relationship Management'" /> IT team</li>
			<li>End-user support & training; ITIL Problem & Change Management</li>
			<!-- <li>Salesforce deployment</li> -->

			<!-- Strategy -->
			<li>
				Intensive exchange
				<!--and collaboration-->
				with top management and CoE to define <TooltipAbbreviation :short="'CRM'" :long="'Customer Relationship Management'" /> strategy and establish
				<!--the-->
				Salesforce platform throughout the company
			</li>

			<!-- Hands on -->
			<li>SAP ABAP/NetWeaver/Web <TooltipAbbreviation :short="'GUI'" :long="'Graphical User Interface'" /> Development</li>
			<li>Definition and implementation of data migration and integration with Salesforce</li>
			<!-- Planning -->
			<!-- <li>Effort & cost estimations</li> -->
			<li>Time/scope/cost/quality management</li>

			<!-- Organisational -->
			<!-- <li>Requirements gathering and analysis of current processes</li> -->
			<li>Global coordination of multiple <TooltipAbbreviation :short="'SI'" :long="'System Integrator'" /> partners and Premier Success team</li>
			<li>ISV vendor selection for implementation projects, integration platform and more</li>
			<!-- <li>Stakeholder and 3rd-party management</li> -->
			<li>Interviews with new candidates and employee onboarding</li>
			<!-- Close collaboration with business departments -->
		</template>
		<!-- Program Architect vertretung! -->
		<template v-slot:achievements>
			<li>
				Performed org health check and rearchitecting of existing Salesforce implementation<!-- (in use for 4 years) based on best practices-->;
				<!--reworked many solutions and processes,-->
				improved governor limit usage by 60%;
				<!--and removed redundant implementations-->
				increased test coverage by 15%; reduced exceptions by 75%
				<!-- (implementation improvements and automated no-touch exception handling) -->
			</li>
			<li>
				Established enterprise<!---level sandbox management and general-->
				system landscape management in multi-org strategy (12 env.; 4 integrated env.; 2 integration platforms)
			</li>
			<li>
				Established <TooltipAbbreviation :short="'CI/CD'" :long="'Continuous Integration and Deployment'" /> framework for entire multi-org development life cycle;
				<!-- deployments to multiple orgs at once; -->increased deployment efficiency by 90%
			</li>
			<!-- <li>Established processes for testing, triaging</li> -->
			<li>
				Improved platform performance (reporting, developments, integration) esp. in China
				<!-- incl. single-sign on rework -->
			</li>
			<li>
				Reworked integration approach and reduced total run time by 80%
				<!--(in on instance, reduced complex job from seven hours to just 20 minutes)-->; evaluated, selected and introduced <TooltipAbbreviation :short="'ETL'" :long="'Extract, Transform, Load'" /> tool
			</li>
			<li>Defined new Change Management process<!-- incl. single-sign on rework --></li>
			<!-- <li>Furthermore I defined the communication paths for Change Management and introduced a custom Salesforce app for tracking enhancement requests and issue reports using both declarative and programmatic tools.</li> -->
			<li>Migrated large amounts of business data from various systems</li>
			<li>Migrated from delegated to federated authentication incl. change of identity provider</li>
			<li>Increased efficiency of code development by 75%</li>
			<li>Improved EoY process via automation and reduced work time by 50%</li>
			<li>Established governance framework and Center of Excellence</li>
			<!-- Tools: Git, Jenkins; Force.com Migration Toolkit -->
		</template>
	</ProjectBlock>
	<ProjectBlock v-if="idVal == 7" :pId="7" :products="['platform', 'sales']">
		<template v-slot:logo>
			<img src="../assets/work_schaeffler.svg" width="240" alt="Company Logo Schaeffler" />
		</template>
		<template v-slot:company>
			Global Automotive, Industrial and Aerospace Supplier
		</template>
		<template v-slot:from>
			Apr 2015
		</template>
		<template v-slot:to>
			Nov 2015
		</template>
		<template v-slot:description>
			Complex solution proposal and implementation to ensure legal compliance with German Foreign & Export Control Law using Salesforce in the Automotive Industrial, Aftermarket and OEM sector for a multi-org implementation.
		</template>
		<template v-slot:responsibilities>
			<li>Lead Project Manager</li>
			<li>Evaluation of existing Salesforce implementation</li>
			<li>Definition of complex solution design to ensure full legal compliance throughout the platform and future-proof implementation</li>
		</template>
		<template v-slot:achievements>
			<li>Evaluated impact of German Foreign & Export Control Law to Salesforce and surrounding IT systems and processes with legal experts</li>
			<li>Created architecture, Proof of Concept and transition plan to comply with German Foreign & Export Control Law</li>
			<li>Coordinated close cooperation with Salesorce.com and external <TooltipAbbreviation :short="'SIs'" :long="'System Integrators'" /></li>
			<li>Created cost-benefit analysis</li>
			<li>Assessed end-user impact</li>
		</template>
	</ProjectBlock>
	<ProjectBlock v-if="idVal == 8" :pId="8" :products="['platform', 'sales']" :otherProducts="['SAP ERP ECC 6.0 (SD, MM, HR)', 'SAP BW', 'Microsoft AD', 'Talend Data Integration', 'up2go IHS/CSM']">
		<template v-slot:logo>
			<img src="../assets/work_schaeffler.svg" width="240" alt="Company Logo Schaeffler" />
		</template>
		<template v-slot:company>
			Global Automotive, Industrial and Aerospace Supplier
		</template>
		<template v-slot:from>
			Nov 2013
		</template>
		<template v-slot:to>
			Jul 2014
		</template>
		<template v-slot:description>
			Implementation of a global solution for key account management and market analysis in the Automotive OEM sector.
		</template>
		<template v-slot:responsibilities>
			<li>Lead Project Manager (<TooltipAbbreviation :short="'PoC'" :long="'Proof of Concept'" />); Co-Project Manager (Implementation)</li>
			<li>Requirements gathering and analysis of current processes</li>
			<li>User interviews</li>
			<li>Salesforce architecture, customization, development and deployment</li>
			<li>Integration architecture and development (SAP <TooltipAbbreviation :short="'ERP'" :long="'Enterprise Resource Planning'" />, <TooltipAbbreviation :short="'BW'" :long="'Business Warehouse'" />, Identity Management)</li>
			<li>Rollout planning</li>
			<li>End-user training</li>
			<li>Stakeholder and 3rd-party management</li>
			<li>Time/scope/cost/quality management</li>
		</template>
		<template v-slot:achievements>
			<li>Selected SI partner</li>
			<li>Defined processes for key account management, market and competitor analysis</li>
			<li>Created Proof of Concept and prototype implementation</li>
			<li>Guided successful global implementation</li>
		</template>
	</ProjectBlock>
	<ProjectBlock v-if="idVal == 9" :pId="9" :products="['platform', 'sales']" :otherProducts="['SAP ERP ECC 6.0 (CS, SD, MM)', 'SAP BW', 'SAP HR', 'Microsoft AD', 'Talend Data Integration']">
		<template v-slot:logo>
			<img src="../assets/work_schaeffler.svg" width="240" alt="Company Logo Schaeffler" />
		</template>
		<template v-slot:company>
			Global Automotive, Industrial and Aerospace Supplier
		</template>
		<template v-slot:from>
			Sep 2013
		</template>
		<template v-slot:to>
			Oct 2014
		</template>
		<template v-slot:description>
			Planning, implementation and rollout of a harmonized platform for Customer Service, Sales and Marketing in the Automotive Aftermarket sector.
			<!-- <li>500 users</li> -->
			<!-- Furthermore I was in charge of planning and implementing the whole Salesforce data architecture and security model.
											Permanent communication and consultation
											with the internal customers, other IT departments, external providers, Salesforce.com and management ensured a very efficient and successful implementation in a timely manner.
											My personal workload was very high throughout the entire project, as our Salesforce Implementation Partner, a Global Strategic Partner, could not deliver on the promised Salesforce expertise. -->
		</template>
		<template v-slot:responsibilities>
			<li>Lead Project Manager (PoC & implementation)</li>
			<li>Requirements gathering and analysis of current processes</li>
			<li>User interviews</li>
			<li>Salesforce architecture, customization, development and deployment</li>
			<li>Integration architecture and development (SAP <TooltipAbbreviation :short="'ERP'" :long="'Enterprise Resource Planning'" />, <TooltipAbbreviation :short="'BW'" :long="'Business Warehouse'" />, Identity Management)</li>
			<li>SAP ABAP/NetWeaver Development</li>
			<li>Rollout planning</li>
			<li>End-user training</li>
			<li>Stakeholder and 3rd-party management</li>
			<li>Time/scope/cost/quality management</li>
		</template>
		<template v-slot:achievements>
			<li>Established central platform for the Customer Service, Sales Representatives and Marketing for managing their leads and customers</li>
			<li>Improved operational efficiency</li>
			<li>Increased transparency for both sales representatives as well as customer service</li>
			<li>Enabled an effective internal communication and collaboration using Chatter and other tools</li>
			<li>Built integration with various enterprise systems</li>
			<li>Successful global implementation</li>
		</template>
	</ProjectBlock>
	<ProjectBlock v-if="idVal == 10" :pId="10" :products="['platform', 'sales']" :otherProducts="['SAP ERP R/3 and ECC 6.0 (CS, SD, MM, PP, SCM, MRP, PLM, LO, PM, GTS, GRC, CUA)']">
		<template v-slot:logo>
			<img src="../assets/work_schaeffler.svg" width="240" alt="Company Logo Schaeffler" />
		</template>
		<template v-slot:company>
			Global Automotive, Industrial and Aerospace Supplier
		</template>
		<template v-slot:from>
			May 2011
		</template>
		<template v-slot:to>
			Sep 2013
		</template>
		<template v-slot:description>
			Internships in various IT departments
		</template>
		<template v-slot:responsibilities>
			<li>IT Sales/<TooltipAbbreviation :short="'CRM'" :long="'Customer Relationship Management'" /> (May 2013—Sep 2013):</li>
			<li>IT SAP ERP Production (Oct 2012—Feb 2013)</li>
			<li>IT SAP Information Controlling (Apr 2012—Sep 2012)</li>
			<li>IT SAP Logistics (Oct 2011—Mar 2012)</li>
			<li>IT SAP Basis (May 2011—Sep 2011)</li>
		</template>
		<template v-slot:achievements>
			<li>Restructured customer-specific extensions and processes for the SAP Shop Floor Control regarding goods receipt posting (Bachelor's thesis)</li>
			<li>Created concept for managing emergency super user access to SAP <TooltipAbbreviation :short="'ERP'" :long="'Enterprise Resource Planning'" /> systems incl. evaluation of software vendors using the Analytic Hierarchy Process</li>
			<li>Created concept for an integration of the central user administration of SAP and SQL databases</li>
			<li>Designed and built MVP for a Java-based solution for the GUI strategy of the SAP warehouse management system</li>
		</template>
	</ProjectBlock>
</template>

<script>
	import ProjectBlock from './ProjectBlock';
	import TooltipAbbreviation from './TooltipAbbreviation';

	export default {
		name: 'Projects',
		props: {
			id: Number,
		},
		components: {
			ProjectBlock,
			TooltipAbbreviation,
		},
		methods: {},
		data() {
			return {
				idVal: this.id,
			};
		},
		mounted() {},
		setup() {},
	};
</script>
