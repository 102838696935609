import { createApp } from 'vue';

import App from './components/App.vue';

// Vue Google Tags Manager
// Get Property Id from Analytics.Google.com > Admin > Property Settings
import { createGtm } from '@gtm-support/vue-gtm';

// Create cookies
import { VueCookieNext } from 'vue-cookie-next'; // https://github.com/anish2690/vue-cookie-next

// Library
import { library } from '@fortawesome/fontawesome-svg-core';
// Unused libraries https://github.com/FortAwesome/vue-fontawesome#why-use-the-concept-of-a-library
// import {
// 	faCoffee
// } from '@fortawesome/free-solid-svg-icons'
// import {
// 	faSpinner
// } from '@fortawesome/pro-light-svg-icons'

// Import dedicated icon (saves space)
import { faEnvelope, faDownload, faHome, faPaperPlane, faChevronCircleDown, faBars, faGlobeAmericas, faTasks, faPeopleCarry, faProjectDiagram, faHandHoldingMedical, faHandsHelping, faCog, faFile, faInfoCircle, faChevronLeft, faWindowClose, faSitemap, faCrown, faUsers, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
// To import all, use import {fab} - but best is to only import those you need
import { faLinkedinIn, faXing, faGithub, faSalesforce } from '@fortawesome/free-brands-svg-icons';
// npm i --save @fortawesome/vue-fontawesome@3.0.0-1
// If you use just vuq-fontawesome you will get error messages
// Get icon names from https://fontawesome.com/icons/linkedin-in?style=brands
// Or simply check the folder D:\Git\sharingDesigner\vue3_NEW\CurriculumVitae\node_modules\@fortawesome\
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faEnvelope, faDownload, faHome, faPaperPlane, faChevronCircleDown, faBars, faGlobeAmericas, faTasks, faPeopleCarry, faProjectDiagram, faHandHoldingMedical, faHandsHelping, faCog, faFile, faInfoCircle, faChevronLeft, faWindowClose, faClipboardCheck, faSitemap, faCrown, faUsers, faLinkedinIn, faXing, faGithub, faSalesforce);

const app = createApp(App);

// To control Google Analytics - wait for consent (alternative: https://developers.google.com/gtagjs/devguide/consent)
// 1. Create Analytics (use "Use Universal", as I do not need an app)
// 2. Create Google Tags Manager > New Tag > G4A > e.g. G-BB3H15Y4M9; set field "anonymizeIp" = true
// 3. Analytics: Create Web Stream
// x. Use "Google Tag Manager" Chrome addon to confirm
// NOTE: Ensure that Cookie consent is accepted to test
// See here about Ids: https://support.google.com/analytics/thread/13109681?hl=en
// NOTE Debugging is now easier - instead of the Chrome plugin, use https://tagassistant.google.com/#!#source=TAG_MANAGER&id=GTM-MQJSL7H&gtm_auth=FuW_jW6nn5Dj4C1nfmJc0Q&gtm_preview=env-5&cb=1524134783127491 (accept cookies to "Continue")
// Or use "Preview" button on GTM homepage
// If GTM fires, that is good - but doesn't mean GA is going to receive it. GTM is just a simple tag manager - if the GA analytics stored in it is wrong, GA won't see it. If you have cookies disabled or an ad blocker, it won't work.
app.use(
	createGtm({
		id: 'GTM-MQJSL7H', // Google Tag Manager Id
		// queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
		// 	gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
		// 	gtm_preview: 'env-4',
		// 	gtm_cookies_win: 'x'
		// },
		defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
		// FIXME: Was false, and I think that's right so I can control enablement via fookies
		enabled: true, // defaults to true. Plugin can be disabled by setting this to false for example: enabled: !!GDPR_Cookie (optional) -> We disable by default, and wait for cookie consent. TODO: However, don't just store in variable, store consent in cookie to not have to ask on every refresdh
		loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
		// vueRouter: router, // Pass the router instance to automatically sync with router (optional)
		// ignoredViews: ['homepage'], // Don't trigger events for specified router names (case insensitive) (optional)
		// trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
		debug: true, // Whether or not display console logs debugs (optional)
	})
);
app.use(VueCookieNext);
// set default config
// VueCookieNext.config({
// 	expire: '7d'
// })

// set global cookie
// VueCookieNext.setCookie('theme', 'default')
// VueCookieNext.setCookie('hover-time', {
// 	expire: '1s'
// })

app.component('font-awesome-icon', FontAwesomeIcon);

// Note: This works, but it triggers the standalone build instead of the runtime build with Vue. Then you will run into CSP issues that require you to use 'unsafe-eval' (not recommended). This would trigger Vue to use eval() to evaluate anything within the "template" string, which is a risk - see https://vuejsdevelopers.com/2017/05/08/vue-js-chrome-extension/
// https://code.luasoftware.com/tutorials/vuejs/vue-cli-3-include-runtime-compiler/
// https://vuejsdevelopers.com/2017/05/08/vue-js-chrome-extension/
// app.component("modal", {
// 	template: "#modal-template",
// })

app.mount('#app'); // Must be called at the end
