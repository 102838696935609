<!-- Examples (search in google for German text + "site:ch") -->
<!-- https://apalis.ch/datenschutz/ -->
<!-- https://ch.laderach.com/en/privacy-policy/ -->
<!-- https://www.alpamare.ch/en/legal-information/ -->
<!-- https://proitera.ch/en/imprint/ -->
<!-- https://www.herofest.ch/en/data-protection-disclaimer.aspx -->
<!-- https://www.wertfabrik.ch/en/legal-notice-data-protection/ -->
<!-- https://skan.ch/en/contacts/data-protection-declaration -->
<!-- https://www.switzerland-innovation.com/zurich/privacy-policy -->
<!-- https://www.rivopharm.ch/privacy-policy -->
<!-- https://miromico.ch/data-protection/?lang=en -->
<!-- https://www.swico.ch/en/privacy/#basis-for-data-processing -->
<!-- https://www.bellingua.ch/en/privacy-policy/ -->
<!-- https://citygate.ch/en/datenschutzerklaerung/ -->
<!-- https://www.self-fitness.ch/Home/datenschutzerklaerung/?oid=118&lang=de -->
<!-- https://baslerpark.ch/en/privacy-policy/ -->
<!-- https://soodring.ch/cookie-policy/ -->
<!-- https://mb-gebaeudetechnik.ch/cookie-policy/ -->
<!-- https://qsv.ch/cookie-policy/ -->
<!-- https://www.36grad.ch/cookie-policy/ -->
<!-- https://matrixzone.ch/cookie-policy/ -->
<!-- https://www.extheria.com/data-protection-policy -->
<!-- https://www.sellandbuycapacity.ch/datenschutz-und-privacy-policy/ -->
<!-- https://apalis.ch/datenschutz/ -->
<!-- http://www.timetoshine.cc/impressum -->
<!-- https://www.swissmedic.ch/swissmedic/en/home/legal-framework.html -->
<!-- https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html#englische-version-ihrer-muster-datenschutzerklaerung -->
<template>
	<transition name="modal-fade">
		<!-- To hide the modal, we need to change the isShowModal variable in our parent component (CurriculumVitae). We do this by emitting a "close" event, which triggers @close in CurriculumVitae - see https://forum.vuejs.org/t/passing-data-back-to-parent/1201 -->
		<div class="modal-backdrop" role="dialog" @click="$emit('close')">
			<div class="modal" ref="modal">
				<header class="modal-header">
					<h1>
						<slot name="header"> </slot>
						<template v-if="language == 'DE'">
							Datenschutz&shy;erkl&auml;rung
						</template>
						<template v-if="language == 'EN'">
							Privacy Policy
						</template>
					</h1>
					<button type="button" class="button-close" @click="$emit('close')" aria-label="Close modal">
						<font-awesome-icon :icon="['fas', 'window-close']" class="contact-button-icon" />
					</button>
					<div class="sep"></div>
				</header>

				<!-- https://www.webkinder.ch/blog/strategie/wie-du-google-analytics-dsgvo-konform-machst-und-warum-datenschutz-uns-alle-betrifft/
  https://easyrechtssicher.de/opt-in-cookie-banner/#3
  https://www.e-recht24.de/artikel/datenschutz/6843-google-analytics-datenschutz-rechtskonform-nutzen.html -->
				<section class="modal-body">
					<slot name="body"> </slot>
					<div class="modal-body-buttons" v-if="showLanguagesVal">
						<button type="button" @click="getLanguage('EN')" @click.stop="">
							English
						</button>
						<button type="button" @click="getLanguage('DE')" @click.stop="">
							German
						</button>
					</div>
					<template v-if="language == 'DE'">
						<p>Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
						<address>
							<span class="n-obfuscate" data-firstName="Michael" data-lastName="Schmidt-Korth"></span><br />
							<span class="a-obfuscate" data-street="Ringstrasse" data-nr="23" data-zip="8057" data-city="Zürich"></span>
						</address>
						<address>
							<a href="javascript:void(0);" class="e-obfuscate" data-name="michael" data-domain="schmidt-korth" data-tld="de" onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"></a>
						</address>
						<address>
							<a href="https://michael-schmidtkorth.de">https://michael-schmidtkorth.de</a>
						</address>
						<p>&nbsp;</p>
						<h2>Allgemeiner Hinweis</h2>
						<p>
							Gest&uuml;tzt auf Artikel 13 der schweizerischen Bundesverfassung und den datenschutzrechtlichen Bestimmungen des Bundes (Datenschutzgesetz, DSG<!--DSG-->) hat jede Person Anspruch auf Schutz ihrer Privatsph&auml;re sowie auf Schutz vor Missbrauch ihrer pers&ouml;nlichen
							Daten. Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerkl&auml;rung.
						</p>
						<p>In Zusammenarbeit mit unseren Hosting-Providern bem&uuml;hen wir uns, die Datenbanken so gut wie m&ouml;glich vor fremden Zugriffen, Verlusten, Missbrauch oder vor F&auml;lschung zu sch&uuml;tzen.</p>
						<p>Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.</p>
						<p>
							Durch die Nutzung dieser Website erkl&auml;ren Sie sich mit der Erhebung, Verarbeitung und Nutzung von Daten gem&auml;ss der nachfolgenden Beschreibung einverstanden. Diese Website kann grunds&auml;tzlich ohne Registrierung besucht werden. Dabei werden Daten wie
							beispielsweise aufgerufene Seiten bzw. Namen der abgerufenen Datei, Datum und Uhrzeit zu statistischen Zwecken auf dem Server gespeichert, ohne dass diese Daten unmittelbar auf Ihre Person bezogen werden. Personenbezogene Daten, insbesondere Name, Adresse oder
							E-Mail-Adresse werden soweit m&ouml;glich auf freiwilliger Basis erhoben. Ohne Ihre Einwilligung erfolgt keine Weitergabe der Daten an Dritte.
						</p>
						<p>&nbsp;</p>
						<h2>Bearbeitung von Personendaten</h2>
						<p>
							Personendaten sind alle Angaben, die sich auf eine bestimmte oder bestimmbare Person beziehen. Eine betroffene Person ist eine Person, &uuml;ber die Personendaten bearbeitet werden. Bearbeiten umfasst jeden Umgang mit Personendaten, unabh&auml;ngig von den angewandten
							Mitteln und Verfahren, insbesondere das Aufbewahren, Bekanntgeben, Beschaffen, L&ouml;schen, Speichern, Ver&auml;ndern, Vernichten und Verwenden von Personendaten.
						</p>
						<p>Wir bearbeiten Personendaten im Einklang mit dem schweizerischen Datenschutzrecht. Im &Uuml;brigen bearbeiten wir – soweit und sofern die EU-DSGVO anwendbar ist – Personendaten gem&auml;ss folgenden Rechtsgrundlagen im Zusammenhang mit Art. 6 Abs. 1 DSGVO<!--DSGVO-->:</p>
						<ul>
							<li>lit. a) Bearbeitung von Personendaten mit Einwilligung der betroffenen Person.</li>
							<li>lit. b) Bearbeitung von Personendaten zur Erf&uuml;llung eines Vertrages mit der betroffenen Person sowie zur Durchf&uuml;hrung entsprechender vorvertraglicher Massnahmen.</li>
							<li>
								lit. c) Bearbeitung von Personendaten zur Erf&uuml;llung einer rechtlichen Verpflichtung, der wir gem&auml;ss allenfalls anwendbarem Recht der EU oder gem&auml;ss allenfalls anwendbarem Recht eines Landes, in dem die DSGVO<!--DSGVO-->
								ganz oder teilweise anwendbar ist, unterliegen.
							</li>
							<li>lit. d) Bearbeitung von Personendaten um lebenswichtige Interessen der betroffenen Person oder einer anderen nat&uuml;rlichen Person zu sch&uuml;tzen.</li>
							<li>
								lit. f) Bearbeitung von Personendaten um die berechtigten Interessen von uns oder von Dritten zu wahren, sofern nicht die Grundfreiheiten und Grundrechte sowie Interessen der betroffenen Person &uuml;berwiegen. Berechtigte Interessen sind insbesondere unser
								betriebswirtschaftliches Interesse, unsere Website bereitstellen zu k&ouml;nnen, die Informationssicherheit, die Durchsetzung von eigenen rechtlichen Anspr&uuml;chen und die Einhaltung von schweizerischem Recht.
							</li>
						</ul>
						<p>
							Wir bearbeiten Personendaten f&uuml;r jene Dauer, die f&uuml;r den jeweiligen Zweck oder die jeweiligen Zwecke erforderlich ist. Bei l&auml;nger dauernden Aufbewahrungspflichten aufgrund von gesetzlichen und sonstigen Pflichten, denen wir unterliegen, schr&auml;nken wir
							die Bearbeitung entsprechend ein.
						</p>
						<p>&nbsp;</p>
						<h2>Datenschutz&shy;er&shy;kl&auml;&shy;rung f&uuml;r Cookies</h2>
						<p>
							Diese Website verwendet Cookies. Das sind kleine Textdateien, die es m&ouml;glich machen, auf dem Endger&auml;t des Nutzers spezifische, auf den Nutzer bezogene Informationen zu speichern, w&auml;hrend er die Website nutzt. Cookies erm&ouml;glichen es, insbesondere Nutzungsh&auml;ufigkeit und Nutzeranzahl der Seiten zu ermitteln, Verhaltensweisen der Seitennutzung zu analysieren, aber auch unser Angebot kundenfreundlicher zu gestalten. Cookies bleiben &uuml;ber das Ende einer Browser-Sitzung gespeichert und k&ouml;nnen bei einem erneuten Seitenbesuch wieder aufgerufen werden. Wenn Sie das nicht w&uuml;nschen, sollten Sie Ihren Internetbrowser so einstellen, dass er die Annahme von Cookies verweigert.
						</p>
						<p>Ein spezielles Cookie wird genutzt um die vom Anwender getätigte Cookie-Zustimmung über sieben Tage zu speichern.</p>
						<p>
							Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der Dienste, vor allem im Fall des Trackings, über die US-amerikanische Seite <a href="http://www.aboutads.info/choices/" target="_blank" rel="noopener">http://www.aboutads.info/choices/</a> oder die EU-Seite <a href="http://www.youronlinechoices.com/" target="_blank" rel="noopener">http://www.youronlinechoices.com/</a> erklärt werden. Des Weiteren kann die Speicherung von Cookies mittels deren Abschaltung in den Einstellungen des Browsers erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen dieses Onlineangebotes genutzt werden können.
						</p>
						<p>&nbsp;</p>
						<h2>Datenschutz&shy;er&shy;kl&auml;&shy;rung f&uuml;r SSL-/TLS-Verschl&uuml;sselung</h2>
						<p>
							Diese Website nutzt aus Gr&uuml;nden der Sicherheit und zum Schutz der &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-/TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung erkennen Sie
							daran, dass die Adresszeile des Browsers von &quot;http://&quot; auf &quot;https://&quot; wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
						</p>
						<p>Wenn die SSL bzw. TLS Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten mitgelesen werden.</p>
						<p>&nbsp;</p>
						<h2>Datenschutz&shy;er&shy;kl&auml;&shy;rung f&uuml;r Server-Log-Files</h2>
						<p>Der Provider dieser Website erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch an uns &uuml;bermittelt. Dies sind:</p>
						<ul>
							<li>Browsertyp und Browserversion</li>
							<li>verwendetes Betriebssystem</li>
							<li>Referrer URL</li>
							<li>Hostname des zugreifenden Rechners</li>
							<li>IP-Addresse des zugreifenden Rechners</li>
							<li>Uhrzeit der Serveranfrage</li>
						</ul>
						<p>
							Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachtr&auml;glich zupr&uuml;fen, wenn uns konkrete Anhaltspunkte f&uuml;r eine rechtswidrige
							Nutzung bekannt werden.
						</p>
						<p>&nbsp;</p>
						<h2>Dienste von Dritten</h2>
						<p>Diese Website verwenden allenfalls Google Maps f&uuml;r das Einbetten von Karten, Google Invisible reCAPTCHA f&uuml;r den Schutz gegen Bots und Spam sowie YouTube f&uuml;r das Einbetten von Videos.</p>
						<p>
							Diese Dienste der amerikanischen Google LLC verwenden unter anderem Cookies und infolgedessen werden Daten an Google in den USA &uuml;bertragen, wobei wir davon ausgehen, dass in diesem Rahmen kein personenbezogenes Tracking allein durch die Nutzung unserer Website
							stattfindet.
						</p>
						<p>Google hat sich verpflichtet, einen angemessenen Datenschutz gem&auml;ss dem amerikanisch-europ&auml;ischen und dem amerikanisch-schweizerischen Privacy Shield zu gew&auml;hrleisten.</p>
						<p>Weitere Informationen finden sich in der <a href="https://policies.google.com/privacy?hl=de" rel="noopener" target="_blank">Datenschutzerkl&auml;rung von Google</a>.</p>
						<p>Diese Website nutz einen Email-Relayig-Anbieter (SendGrid). Weitere Informationen finden sich untig innerhalb der "Datenschutzerkl&auml;rung f&uuml;r Kontaktformular"</p>.

						<p>&nbsp;</p>
						<h2>Datenschutz&shy;er&shy;kl&auml;&shy;rung f&uuml;r Kontaktformular</h2>
						<p>
							Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und f&uuml;r den Fall von Anschlussfragen bei uns gespeichert
							<!-- CUSTOM -->und per E-Mail weitergeleitet<!-- CUSTOM -->. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
						</p>
							<p>Die folgenden Informationen werden gesammelt wie vom Anwender eingegeben zum Zwecke der Kontaktaufnahme mit dem Verantwortlichen dieser Website:</p>
							<ul>
								<li>Vor- und Nachname</li>
								<li>E-Mail Addresse</li>
							</ul>
							<p>
								Diese Informationen werden an einen Email-Relaying-Service übertragen (SendGrid). Dieser Anbieter verwendet Server in den USA und ist Privacy Shield Certified. Die Informationen werden zudem als Email auf einem Server in Deutschland gespeichert bis der Grund der vom Anwender initiierten Kontaktaufnahme abgeschlossen ist oder der Anwender anfordert seine Daten zu löschen.</p>
							<p>Die eingegebenen Informationen werden nicht verwendet um den Anwender zu kontaktieren falls die Kontaktaufnahme nicht durch den Anwender stattgefunden hat. Die bereitgestellten Informationen werden nicht mit anderen Drittanbeitern neben den genannten zur E-Mail-Bearbeitung geteilt. Der Anwender kann die <a href="https://sendgrid.com/policies/security/" target="_blank" rel="noopener">Privacy Policy von SendGrid</a> und das <a href="https://www.twilio.com/legal/data-protection-addendum" target="_blank" rel="noopener">Datenschutz-Addendum</a> konsultieren.</p>
						<p>&nbsp;</p>
						<h2>Datenschutz&shy;er&shy;kl&auml;&shy;rung f&uuml;r Google Analytics</h2>
						<p>
							Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Ireland Limited. Wenn der Verantwortliche für die Datenverarbeitung auf dieser Website ausserhalb des Europäischen Wirtschaftsraumes oder der Schweiz sitzt, dann erfolgt die Google Analytics
							Datenverarbeitung durch Google LLC. Google LLC und Google Ireland Limited werden nachfolgend &laquo;Google&raquo; genannt.
						</p>
						<p>
							Über die gewonnenen Statistiken können wir unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten. Diese Website verwendet Google Analytics zudem für eine geräteübergreifende Analyse von Besucherströmen, die über eine User-ID durchgeführt wird. Sofern
							Sie über ein Google-Benutzerkonto verfügen, können Sie in den dortigen Einstellungen unter &laquo;Meine Daten&raquo;, &laquo;persönliche Daten&raquo; die geräteübergreifende Analyse Ihrer Nutzung deaktivieren.
						</p>
						<p>
							Rechtsgrundlage für die Nutzung von Google Analytics ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Wir weisen Sie darauf hin, dass auf dieser
							Website Google Analytics um den Code &laquo;_anonymizeIp();&raquo; erweitert wurde, um eine anonymisierte Erfassung von IP-Adressen zu gewährleisten. Dadurch werden IP-Adressen gekürzt weiterverarbeitet, eine Personenbeziehbarkeit kann damit ausgeschlossen werden. Soweit
							den über Sie erhobenen Daten ein Personenbezug zukommt, wird dieser also sofort ausgeschlossen und die personenbezogenen Daten damit umgehend gelöscht.
						</p>
						<p>
							Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
							Websitenaktivitäten zusammenzustellen und um weitere mit der Websitennutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitenbetreiber zu erbringen. Für die Ausnahmefälle, in denen personenbezogene Daten in die USA übertragen werden, hat sich
							Google dem EU-US Privacy Shield unterworfen, <a href="https://www.privacyshield.gov/EU-US-Framework" target="_blank" rel="noopener">https://www.privacyshield.gov/EU-US-Framework</a>.
						</p>
						<p>
							Google Analytics verwendet Cookies. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Sie können die Speicherung der Cookies durch eine entsprechende
							Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten
							und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:
							<a href="https://www.swissanwalt.ch/de/gaoptout.aspx" target="_blank" rel="noopener">Google Analytics deaktivieren</a>.
						</p>
						<p>
							Ausserdem können Sie die Nutzung von Google Analytics auch verhindern, indem sie auf diesen Link klicken: <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener">Google Analytics deaktivieren</a>. Hierdurch wird ein sog. opt-out Cookie auf
							ihrem Datenträger gespeichert, der die Verarbeitung personenbezogener Daten durch Google Analytics verhindert. Bitte beachten Sie, dass bei einem Löschen sämtlicher Cookies auf Ihrem Endgerät auch diese Opt-out-Cookies gelöscht werden, d.h., dass Sie erneut die
							Opt-out-Cookies setzen müssen, wenn Sie weiterhin diese Form der Datenerhebung verhindern wollen. Die Opt-out-Cookies sind pro Browser und Rechner/Endgerät gesetzt und müssen daher für jeden Browser, Rechner oder anderes Endgerät gesondert aktiviert werden.
						</p>
						<p>&nbsp;</p>
						<h2>Datenschutz&shy;er&shy;kl&auml;&shy;rung für die Nutzung von Google Web Fonts</h2>
						<p>
							Diese Website nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
							Wenn Ihr Browser Web Fonts nicht unterst&uuml;tzt, wird eine Standardschrift von Ihrem Computer genutzt.
						</p>
						<p>
							Weitere Informationen zu Google Web Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq" target="_blank" rel="noopener">https://developers.google.com/fonts/faq</a> und in der Datenschutzerkl&auml;rung von Google:
							<a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener">https://www.google.com/policies/privacy/</a>
						</p>
						<!-- <p>&nbsp;</p>
						<h2>Google Tag Manager</h2>
						<p>
							Google Tag Manager ist eine L&ouml;sung, mit der wir sog. Website-Tags &uuml;ber eine Oberfl&auml;che verwalten k&ouml;nnen und so z.B. Google Analytics sowie andere Google-Marketing-Dienste in unser Onlineangebot einbinden können. Der Tag Manager selbst, welcher die Tags
							implementiert, verarbeitet keine personenbezogenen Daten der Nutzer. Im Hinblick auf die Verarbeitung der personenbezogenen Daten der Nutzer wird auf die folgenden Angaben zu den Google-Diensten verwiesen. Nutzungsrichtlinien:
							<a href="https://www.google.com/intl/de/tagmanager/use-policy.html" target="_blank" rel="noopener">https://www.google.com/intl/de/tagmanager/use-policy.html</a>.
						</p> -->
						<!-- <p>&nbsp;</p>
						<h2>Newsletter - Mailchimp</h2>
						<p>
							Der Versand der Newsletter erfolgt mittels des Versanddienstleisters 'MailChimp', einer Newsletterversandplattform des US-Anbieters Rocket Science Group, LLC, 675 Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA. Die Datenschutzbestimmungen des Versanddienstleisters
							k&ouml;nnen Sie <a href="https://mailchimp.com/legal/privacy/" target="_blank" rel="noopener">hier</a> einsehen. The Rocket Science Group LLC d/b/a MailChimp ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europ&auml;isches
							Datenschutzniveau einzuhalten (<a href="https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&status=Active&t=1" target="_blank" rel="noopener">PrivacyShield</a>). Der Versanddienstleister wird auf Grundlage unserer berechtigten Interessen gem. Art. 6 Abs. 1 lit. f
							DSGVO und eines Auftragsverarbeitungsvertrages gem. Art. 28 Abs. 3 S. 1 DSGVO eingesetzt.
						</p>
						<p>
							Der Versanddienstleister kann die Daten der Empf&auml;nger in pseudonymer Form, d.h. ohne Zuordnung zu einem Nutzer, zur Optimierung oder Verbesserung der eigenen Services nutzen, z.B. zur technischen Optimierung des Versandes und der Darstellung der Newsletter oder f&uuml;r
							statistische Zwecke verwenden. Der Versanddienstleister nutzt die Daten unserer Newsletterempf&auml;nger jedoch nicht, um diese selbst anzuschreiben oder um die Daten an Dritte weiterzugeben.
						</p> -->
						<p>&nbsp;</p>
						<h2>Hinweis zur Datenweitergabe in die USA</h2>
						<p>
							Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz in den USA eingebunden. Wenn diese Tools aktiv sind, k&ouml;nnen Ihre personenbezogenen Daten an die US-Server der jeweiligen Unternehmen weitergegeben werden.
							<!-- Custom -->
							Ausserdem befindet sich der Serverstandort dieser Website in den USA. Der Anwender kann das <a href="https://vercel.com/legal/dpa" target="_blank" rel="noopener">Data Protection Addendum</a> konsultieren.
							<!-- Custom -->
							Wir weisen darauf hin, dass die USA kein sicherer Drittstaat im Sinne des EU-Datenschutzrechts sind. US-Unternehmen sind dazu verpflichtet, personenbezogene Daten an Sicherheitsbeh&ouml;rden herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen
							k&ouml;nnten. Es kann daher nicht ausgeschlossen werden, dass US-Beh&ouml;rden (z.B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu &uuml;berwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese Verarbeitungst&auml;tigkeiten keinen
							Einfluss.
						</p>
						<p>&nbsp;</p>
						<h2>Urheberrechte</h2>
						<p>
							Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf der Website, geh&ouml;ren ausschliesslich dem Betreiber dieser Website oder den speziell genannten Rechteinhabern. Für die Reproduktion von sämtlichen Dateien, ist die schriftliche
							Zustimmung des Urheberrechtstr&auml;gers im Voraus einzuholen.
						</p>
						<p>Wer ohne Einwilligung des jeweiligen Rechteinhabers eine Urheberrechtsverletzung begeht, kann sich strafbar und allenfalls schadenersatzpflichtig machen.</p>
						<p>&nbsp;</p>
						<h2>Allgemeiner Haftungsausschluss</h2>
						<p>
							Alle Angaben unseres Internetangebotes wurden sorgf&auml;ltig gepr&uuml;ft. Wir bem&uuml;hen uns, unser Informationsangebot aktuell, inhaltlich richtig und vollst&auml;ndig anzubieten. Trotzdem kann das Auftreten von Fehlern nicht v&ouml;llig ausgeschlossen werden, womit
							wir keine Garantie f&uuml;r Vollst&auml;ndigkeit, Richtigkeit und Aktualit&auml;t von Informationen auch journalistisch-redaktioneller Art &uuml;bernehmen k&ouml;nnen. Haftungsanspr&uuml;che aus Sch&auml;den materieller oder ideeller Art, die durch die Nutzung der
							angebotenen Informationen verursacht wurden, sind ausgeschlossen, sofern kein nachweislich vors&auml;tzliches oder grob fahrl&auml;ssiges Verschulden vorliegt.
						</p>
						<p>
							Der Herausgeber kann nach eigenem Ermessen und ohne Ank&uuml;ndigung Texte ver&auml;ndern oder l&ouml;schen und ist nicht verpflichtet, Inhalte dieser Website zu aktualisieren. Die Benutzung bzw. der Zugang zu dieser Website geschieht auf eigene Gefahr des Besuchers. Der
							Herausgeber, seine Auftraggeber oder Partner sind nicht verantwortlich f&uuml;r Sch&auml;den, wie direkte, indirekte, zuf&auml;llige, vorab konkret zu bestimmende oder Folgesch&auml;den, die angeblich durch den Besuch dieser Website entstanden sind und &uuml;bernehmen
							hierf&uuml;r folglich keine Haftung.
						</p>
						<p>
							Der Herausgeber &uuml;bernimmt ebenfalls keine Verantwortung und Haftung f&uuml;r die Inhalte und die Verf&uuml;gbarkeit von Website Dritter, die &uuml;ber externe Links dieser Website erreichbar sind. F&uuml;r den Inhalt der verlinkten Seiten sind ausschliesslich deren
							Betreiber verantwortlich. Der Herausgeber distanziert sich damit ausdr&uuml;cklich von allen Inhalten Dritter, die m&ouml;glicherweise straf- oder haftungsrechtlich relevant sind oder gegen die guten Sitten verstossen.
						</p>
						<p>&nbsp;</p>
						<h2>&Auml;nderungen</h2>
						<p>
							Wir k&ouml;nnen diese Datenschutzerkl&auml;rung jederzeit ohne Vorank&uuml;ndigung anpassen. Es gilt die jeweils aktuelle, auf unserer Website publizierte Fassung. Soweit die Datenschutzerkl&auml;rung Teil einer Vereinbarung mit Ihnen ist, werden wir Sie im Falle einer
							Aktualisierung über die &Auml;nderung per E-Mail oder auf andere geeignete Weise informieren.
						</p>
						<p>&nbsp;</p>
						<h2>Fragen an den Daten&shy;schutz&shy;beauf&shy;trag&shy;ten</h2>
						<p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die f&uuml;r den Datenschutz zu Beginn der Datenschutzerkl&auml;rung aufgef&uuml;hrten, verantwortlichen Person.</p>
						<p>&nbsp;</p>
						<p>
							Zürich, 2020-11-01<!--ACHTUNG: Wenn Sie die Quelle ohne Erlaubnis von SwissAnwalt entfernen, dann begehen Sie eine Urheberrechtsverletzung welche in jedem Fall unter Kostenfolge geahndet wird.--><br />Quelle:
							<a href="https://www.swissanwalt.ch" target="_blank" rel="noopener">SwissAnwalt</a>
						</p>
					</template>
					<template v-if="language == 'EN'">
						<p>Responsible body in the sense of the data protection laws, in particular the EU data protection basic regulation (DSGVO), is:</p>
						<address>
							<span class="n-obfuscate" data-firstName="Michael" data-lastName="Schmidt-Korth"></span><br />
							<span class="a-obfuscate" data-street="Ringstrasse" data-nr="23" data-zip="8057" data-city="Zurich"></span>
						</address>
						<address>
							<a href="javascript:void(0);" class="e-obfuscate" data-name="michael" data-domain="schmidt-korth" data-tld="de" onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"></a>
						</address>
						<address>
							<a href="https://michael-schmidtkorth.de">https://michael-schmidtkorth.de</a>
						</address>
						<p>&nbsp;</p>
						<h2>General Note</h2>
						<p>
							Based on Article 13 of the Federal Constitution and the provisions of federal data protection legislation, everyone has the right to the protection of their privacy, as well as to protection from misuse of their personal details. The responsible part for this website
							complies with these provisions. Personal data is treated as strictly confidential and within the data privacy laws and this privacy policy.
						</p>
						<p>
							In close cooperation with our hosting provider, we make every effort to protect the databases as well as possible from outside access, data loss, misuse or falsification.
						</p>
						<p>
							We point out that data transmissions via internet (e.g email communication) cannot be entirely secure and may have security vulnerabilities. A complete protection of personal details against unauthorised access by third parties is not possible.
						</p>
						<p>
							By using this website, you consent to the collection, processing and use of data as described below. This website can be visited without registration. Data such as pages visited or name of the retrieved file, date and time are stored on the server for statistical
							purposes, without this data being directly referred to your person. Personal data, in particular name, address or e-mail address is collected as far as possible on a voluntary basis. Without your consent, the data will not be passed on to third parties.
						</p>
						<p>&nbsp;</p>
						<h2>Processing of personal data</h2>
						<p>
							Personal data is any information that relates to a specific or identifiable person. An affected person is a person through whom personal information is processed. Editing includes all dealings with personal information, regardless of the means and procedures used,
							including, but not limited to, storing, posting, obtaining, deleting, storing, modifying, destroying and using personal data.
						</p>
						<p>
							We process personal data in accordance with Swiss data protection law. Furthermore, insofar as and insofar as the EU GDPR is applicable, we process personal data according to the following legal bases in connection with Art. 6 (1) GDPR:
						</p>
						<ul>
							<li>a) Processing of personal data with the consent of the data subject.</li>
							<li>b) processing of personal data in order to fulfill a contract with the data subject as well as to carry out corresponding pre-contractual measures.</li>
							<li>c) Processing of personal data in order to fulfill a legal obligation which we are subject to in accordance with any applicable law of the EU or according to any applicable law of a country in which the GDPR is fully or partially applicable.</li>
							<li>d) Processing of personal data to protect the vital interests of the data subject or another natural person.</li>
							<li>
								f) Processing of personal data in order to safeguard the legitimate interests of us or third parties, unless the fundamental freedoms and fundamental rights and interests of the data subject prevail. Justifiable interests are, in particular, our business interest in
								being able to provide our website, information security, the enforcement of our own legal claims and compliance with Swiss law.
							</li>
						</ul>
						<p>
							We process personal information for the duration necessary for the purpose or purposes. For longer-term storage obligations due to legal and other obligations to which we are subject, we restrict the processing accordingly.
						</p>
						<p>&nbsp;</p>
						<h2>Data privacy statement for Cookies</h2>
						<p>
							This website uses cookies. These are small text files that make it possible to store specific user-related information on the user’s electronic device while the user is using the website. Cookies make it possible to determine in particular the frequency of use and the
							number of users of the website, to analyse web navigation behaviour and patterns, and also to make our website more user-friendly. Cookies remain stored at the end of a browser session and can be retrieved when the user revisits the site. If you do not agree with this,
							please change your browser settings to disable cookies.
						</p>
						<p>A dedicated cookie is used to store the cookie consent made by the user.</p>
						<p>
							A general objection to the use of cookies used for online marketing purposes can be declared for many services, especially in the case of tracking, via the US site
							<a href="http://www.aboutads.info/choices/" target="_blank" rel="noopener">http://www.aboutads.info/choices/</a> or the EU site <a href="http://www.aboutads.info/choices/" target="_blank" rel="noopener">http://www.youronlinechoices.com/</a>. Furthermore, the storage of
							cookies can be achieved by disabling them in your browser settings. Please note that in this case you may not be able to use all functions of this website.
						</p>
						<p>&nbsp;</p>
						<h2>Data privacy statement for SSL/TLS encryption</h2>
						<p>
							This website uses SSL/TLS encryption for reasons of security and to protect the transmission of confidential content, such as the requests you send to us as the site operator. You can recognize an encrypted connection by the fact that the address line of your browser
							changes from “http://” to “https://” and by the lock symbol in the address line of your browser.
						</p>
						<p>
							If SSL or TLS encryption is enabled, the data you transmit to us cannot be read by third parties.
						</p>
						<p>&nbsp;</p>
						<h2>Data privacy statement for server log files</h2>
						<p>
							The provider of this website automatically collects and stores information in so-called server log files, which are automatically sent to us via your browser. These are:
						</p>
						<ul>
							<li>Browser type and browser version</li>
							<li>Operating system used</li>
							<li>Referrer URL</li>
							<li>Hostname of the accessing computer</li>
							<li>IP address of the accessing computer</li>
							<li>Time of the server request</li>
						</ul>
						<p>This data cannot be assigned to specific persons. A consolidation of this data with other data sources is not carried out. We reserve the right to check this data subsequently if we become aware of concrete indications of illegal use.</p>
						<p>&nbsp;</p>
						<h2>Third-party services</h2>
						<p>This website may use Google Maps for embedding maps, Google Invisible reCAPTCHA for protection against bots and spam and YouTube for embedding videos.</p>
						<p>
							These services of the American Google LLC use among other things cookies, and as a result data is transferred to Google in the USA. However, we assume that in this context no personal tracking takes place solely through the use of our website.
						</p>
						<p>
							Google is committed to ensuring an appropriate level of data protection in accordance with the American-European and the American-Swiss Privacy Shield.
						</p>
						<p>Further information can be found in <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener">Google’s data privacy statement</a>.</p>

						<p>This website uses an email relayig service (SendGrid). Further information can be found below in the "Privacy Policy for Contact Form"</p>.
						<p>&nbsp;</p>
						<h2>Privacy Policy for Contact Form</h2>
						<p>
							When contacting us (for example via a contact form or an e-mail), the details of the user incl. any entered information are stored and <!-- Custom -->forwarded via e-mail<!-- Custom --> in order to process the request as well as in the event that follow-up questions arise. We do not share your data without your consent.

							The following information is collected as entered by the user for the purpose of contacting the website owner:
							<ul>
								<li>First and Last Name</li>
								<li>E-mail address</li>
							</ul>

							The information will be transmitted to an email relaying service (SendGrid). This service provider's servers are located in the USA and is Privacy Shield certigied. The information will be stored as an email on a server based in Germany until the purpose of the contact initiated by the user has been concluded or the user requests to remove their data.

							The information entered will not be used to contact the user unless initiated by the user. The information provided will not be shared with any third-party vendors except for email processing. You may consult the <a href="https://sendgrid.com/policies/security/" target="_blank" rel="noopener">Privacy Policy of SendGrid</a> and the <a href="https://www.twilio.com/legal/data-protection-addendum" target="_blank" rel="noopener">Data Protection Addendum</a>.
						</p>
						<p>&nbsp;</p>
						<h2>Data privacy statement for Google Analytics</h2>
						<p>
							This website uses Google Analytics, a web analytics service provided by Google Ireland Limited. If the person responsible for data processing on this website is based outside of the European Economic Area or Switzerland, Google Analytics data processing is carried out by
							Google LLC. Google LLC and Google Ireland Limited are referred to as “Google” in the following text.
						</p>
						<p>
							We can use the statistics obtained to improve our offer and to make it more interesting for you as a user. This website also uses Google Analytics for a cross-device analysis of visitor flows, which is carried out via a user ID. If you have a Google user account, you can
							deactivate the cross-device analysis by going to “My Data”, “Personal Data” in your account settings.
						</p>
						<p>
							The legal basis for the use of Google Analytics is Art. 6 para. 1 sentence 1 lit f DS-GVO. The IP address transmitted by your browser within the framework of Google Analytics is not merged with other data from Google. We would like to point out that on this website Google
							Analytics has been extended by the code “_anonymizeIp();” in order to ensure anonymous recording of IP addresses. As a result, IP addresses are processed in a shortened form, so that a personal reference can be excluded. If the data collected about you contains a personal
							reference, this is immediately excluded and the personal data is therefore deleted immediately.
						</p>
						<p>
							Only in exceptional circumstances is the full IP address passed on to a server in the USA and shortened there. On behalf of the operator of this website, Google will use this information in order to evaluate your use of the website, to compile reports on website activity
							and to provide other services relating to website activity and internet usage. For the exceptional cases in which personal data is transferred to the USA, Google has subjected itself to the EU-US Privacy Shield,
							<a href="http://www.aboutads.info/choices/" target="_blank" rel="noopener">https://www.privacyshield.gov/EU-US-Framework</a>.
						</p>
						<p>
							Google Analytics uses cookies. The information generated by the cookie about your use of this website is usually transferred to a Google server in the USA and stored there. You may refuse the use of cookies by selecting the appropriate settings on your browser, however
							please note that if you do this you may not be able to use the full functionality of this website. You can also prevent the collection of data generated by the cookie and related to your use of the website (including your IP address) to Google and the processing of this
							data by Google by downloading and installing the browser plugin available under the following link: <a href="https://www.swissanwalt.ch/de/gaoptout.aspx" target="_blank" rel="noopener">disable Google Analytics</a>.
						</p>
						<p>
							You can also prevent the use of Google Analytics by clicking on this link: Disable Google Analytics. This will save a so-called opt-out cookie on your data carrier, which prevents the processing of personal data by Google Analytics. Please note that if you delete all
							cookies on your end device, these opt-out cookies will also be deleted, i.e. you will have to set the opt-out cookies again if you wish to continue to prevent this form of data collection. The opt-out cookies are set per browser and computer/terminal device and must
							therefore be activated separately for each browser, computer or other terminal device.
						</p>
						<p>&nbsp;</p>
						<h2>Privacy Policy for Google Web Fonts</h2>
						<p>To ensure that fonts used on this website are uniform, this website uses so-called Web Fonts provided by Google. When you access a page on our website, your browser will load the required web fonts into your browser cache to correctly display text and fonts.</p>
						<p>
							To do this, the browser you use will have to establish a connection with Google’s servers. As a result, Google will learn that your IP address was used to access this website. The use of Google Web Fonts is based on Art. 6 Sect. 1 lit. f GDPR. The website operator has a
							legitimate interest in a uniform presentation of the font on the operator’s website.
						</p>
						<p>
							If a respective declaration of consent has been obtained (e.g. consent to the archiving of cookies), the data will be processed exclusively on the basis of Art. 6 Sect. 1 lit. a GDPR. Any such consent may be revoked at any time.
						</p>
						<p>
							If your browser should not support Web Fonts, a standard font installed on your computer will be used.
						</p>
						<p>
							For more information on Google Web Fonts, please consult <a href="https://developers.google.com/fonts/faq" target="_blank" rel="noopener">https://developers.google.com/fonts/faq</a> and Google’s Data Privacy Declaration:
							<a href="https://developers.google.com/fonts/faq" target="_blank" rel="noopener">https://policies.google.com/privacy?hl=en</a>.
						</p>
						<!-- <p>&nbsp;</p>
						<h2>Google Tag Manager/h2>
							<p>
							Google Tag Manager is a solution with which we can manage so-called website tags via an interface and thus integrate
							e.g. Google Analytics and other Google marketing services into our online offer. The Tag Manager itself, which
							implements the tags, does not process any personal data of the users. With regard to the processing of users’ personal
							data, we refer to the following information on Google services. Guidelines for use:
							https://www.google.com/intl/de/tagmanager/use-policy.html.
						</p> -->

						<p>&nbsp;</p>
						<h2>Note on Data Transmission to the USA</h2>
						<p>
							Our website uses tools from companies loacted in the USA. If these tools are enabled, personally identifiable information may be transmitted to US servers of these companies.
							<!-- Custom -->
							Furthermore the location of this website's server is in the USA. The user may consult the <a href="https://vercel.com/legal/dpa" target="_blank" rel="noopener">Data Protection Addendum</a>.
							<!-- Custom -->
							We point out that the US is not a safe third country within the meaning of the EU data protection law. US companies are bound to surrender personal information to security services, without you being able to resort to legal measures. It cannot be ruled out that US
							agencies (e.g. intelligency agencies) process, analyse and permanetnly store your information stored on US servers. We cannot control these activities.
						</p>
						<p>&nbsp;</p>
						<h2>Copyrights</h2>
						<p>
							The copyright and all other rights to content, images, photographs or other files on the website belong exclusively to the controller of this website or to the specially named holders of the rights. The written consent of the copyright holder must be obtained in advance
							for the reproduction of any elements.
						</p>
						<p>A violation of copyright without the consent of the respective holders of the right may be liable to legal prosecution and liable to compensation.</p>
						<p>&nbsp;</p>
						<h2>Disclaimer</h2>
						<p>The author assumes no liability whatsoever with regard to the correctness, accuracy, up-to-dateness, reliability and completeness of the information provided.</p>
						<p>
							Liability claims against the author for damages of a material or immaterial nature arising from access to or use or non-use of the published information, from misuse of the connection or from technical faults are excluded. All offers are non-binding. The author expressly
							reserves the right to change, supplement or delete parts of the pages or the entire offer without prior notice or to discontinue publication temporarily or permanently.
						</p>
						<p>
							References and links to third-party websites are outside our area of responsibility. Any responsibility for such websites is rejected. Access to and use of such websites is at the user’s own risk. The author distances themselves from any content provided by third-parties
							that may be of relevance in penal law.
						</p>
						<p>&nbsp;</p>
						<h2>Changes to the data privacy statement</h2>
						<p>
							We may change this privacy policy at any time without notice. The current version published on our website applies. If the data protection declaration is part of an agreement with you, we will inform you of the change by e-mail or other suitable means in the event of an
							update.
						</p>
						<p>&nbsp;</p>
						<h2>Questions for the Data Protection Officer</h2>
						<p>
							If you have any questions regarding data protection, please write to us by e-mail or contact the person responsible for data protection in our organisation listed at the beginning of this data protection declaration.
						</p>

						<p>
							Zurich, 2020-11-01<!--ACHTUNG: Wenn Sie die Quelle ohne Erlaubnis von SwissAnwalt entfernen, dann begehen Sie eine Urheberrechtsverletzung welche in jedem Fall unter Kostenfolge geahndet wird.--><br />Source:
							<a href="https://www.swissanwalt.ch" target="_blank" rel="noopener">SwissAnwalt</a>
						</p>
					</template>
				</section>

				<!-- FYI Also for additional data protection when registering/registration:  -->
<!-- https://www.swissanwalt.ch/app_sites/member/mysite/default.aspx
Allgemeine Geschäftsbedingungen von SwissAnwalt
Bitte lesen Sie sich diese Bedingungen genau durch, bevor Sie irgendwelche Leistungen von SwissAnwalt über die Webseite
SwissAnwalt.ch oder SwissAnwalt.com (Webseite) in Anspruch nehmen. Durch eine Nutzung der Leistungen akzeptieren Sie
diese Bedingungen und Sie binden sich an diese.

Nutzen Sie die Leistungen von SwissAnwalt nicht, wenn Sie die Nutzungsbedingungen nicht akzeptieren möchten.

1. Allgemeines, Nutzungsausschluss für Minderjährige
Die Plattform wird nachfolgend als Webseite bezeichnet.

SwissAnwalt stellt gegen Gebühren eine Online-Plattform zur Verfügung, über die Sie als Nutzer Fragen von allgemeinem
Inhalt an Rechtsanwältinnen und Rechtsanwälte (im Folgenden Rechtsanwälte) stellen können, die bei SwissAnwalt
angeschlossen sind. Die Webseite erleichtert die Kommunikation mit Rechtsanwälten sowie die Zahlung entstehenden Kosten
an diese.

SwissAnwalt ist für die Inhalte von Fragen und Antworten der Rechtsanwälte nicht verantwortlich. Stellen Sie eine Frage,
so kommt ein Vertrag zwischen Ihnen und dem Rechtsanwalt, nicht aber mit dem Webseite-Betreiber SwissAnwalt zustande.

Im weiteren bietet SwissAnwalt eine Online-Mandatsführung an, in welchem Mandate von Nutzern direkt von einem
Rechtsanwalt online geführt werden. Mandate, welche sich in der Prüfung oder im Offertstadium befinden und für welche
noch keine Vorauszahlung für die Führung des Mandats durch den Nutzer geleistet wurde, gelten noch nicht als vom
Rechtsanwalt übernommen und werden nicht bearbeitet.

Bis zur definitiven Übernahme durch einen Rechtsanwalt, ist der Nutzer selbst für die Einhaltung sämtlicher Fristen
verantwortlich. Vorsicht: Werden Fristen verpasst, kann dies zu einem unwiderruflichen Verlust sämtlicher Rechte führen!

2. Registrierung als Nutzer
Die bei Ihrer Registrierung abgefragten Daten sind vollständig und korrekt anzugeben.

Im Verlauf der Fragestellung oder bei vorgängiger Anmeldung, müssen Sie für Ihr persönliches Konto ein Passwort wählen.

Sie können das Passwort im nachhinein jederzeit wieder ändern. Halten Sie unbedingt Ihr Passwort geheim, damit es
niemandem möglich ist, unter Ihrem «Profil« Fragen an Rechtsanwälte zu stellen oder irgendwelche anderen
Verbindlichkeiten einzugehen. Sie sind für alle Verbindlichkeiten, welche über Ihr Konto eingegangen werden,
vollumfänglich selbst verantwortlich.

Sollten Sie den Verdacht haben, dass jemand ohne Ihre Zustimmung Ihr Profil nutzt, so informieren Sie umgehend
SwissAnwalt unter support@swissanwalt.ch, damit Ihr Profil sowie Ihr allfälliges Guthaben unverzüglich gesperrt werden
können. Für die von Ihnen zu vertretende Nutzung Ihres Profils durch unautorisierte Personen, sind Sie bis zur Sperrung
selbst verantwortlich. Ändern Sie daher auch unverzüglich Ihr Passwort!

3. Abschluss von Verträgen mit SwissAnwalt und Entgelte
Es gibt zwei Wege, um über die Webseite Fragen an Rechtsanwälte zu stellen. Sie können für jede Frage einzelne Zahlungen
leisten. Grundsätzlich ist das Prinzip gleich, jedoch gibt es bei den Bestimmungen, der zu zahlenden Entgelte betreffen,
Unterschiede. Die Bedingungen für Einzelzahler finden Sie nachfolgend unter Ziff. 3.1.

3.1 Nutzung der Webseite und Vertragsschluss mit SwissAnwalt bei Einzelzahlung
Die Registrierung als Nutzer ist grundsätzlich kostenlos. Sämtliche anderen Dienstleistungen von SwissAnwalt - mit
Ausnahme der «Online Anwaltsvermittlung» - sind jedoch kostenpflichtig.

Fragen zur Beantwortung (Online-Rechtsberatung) werden erst beantwortet, wenn Sie ein entsprechendes Honorar einbezahlt
haben (s. hierzu Ziff. 3.1.1) resp. gilt das Mandat (Online-Mandatsführung) erst als angenommen, wenn die vom Anwalt
allenfalls verlangte Vorauszahlung geleistet und bei SwissAnwalt eingetroffen ist.

Zahlungen an SwissAnwalt können per Kreditkarte erfolgen oder mit Einzahlung auf das Konto von SwissAnwalt. SwissAnwalt
speichert keine Kreditkarten-Daten.

3.1.1 Einzelzahlung und Guthabenkonto
Ihre Rechtsfragen werden nur dann an einen Anwalt weitergeleitet, wenn Sie zuvor einen Betrag, den Sie für die
Beantwortung Ihrer Frage mittels Schiebereinstellungen berechnet haben, auf ein Guthabenkonto bei SwissAnwalt überwiesen
und auf dem Konto von SwissAnwalt gutgeschrieben wurde.

Die Zahlung des Betrages erfolgt über Kreditkarte oder durch Einzahlung auf das Konto von SwissAnwalt. Sobald Ihre
Zahlung verifiziert wurde oder auf dem Konto von SwissAnwalt eingegangen ist, kann Ihre Frage durch einen Rechtsanwalt
beantwortet werden.

Kündigen Sie Ihren Nutzer-Account oder wird die laufende Online-Mandatsführung beendet, so erhalten Sie ein vorhandenes
Guthaben über den von Ihnen bei Ihrer Zahlung gewählten Zahlungsweg binnen 30 Tagen nach Kündigung resp. Beendigung des
Mandats (gemäss Schlussrechnung) zurückerstattet. Zinsen für Ihr Guthaben werden keine bezahlt.

3.1.2 Rückzahlungen von Guthaben
Im Falle, dass eine Anfrage nicht beantwortet und auch nicht zur Beantwortung durch einen Rechtsanwalt übernommen worden
ist, können Sie den einbezahlten Betrag auf Ihrem persönlichen Konto durch anklicken des Buttons «Geld zurückzahlen»
zurück verlangen. Dieser Button ist 48 Stunden nach der Fragestellung bis maximal 14 Tage nach Fragestellung aktiv und
im persönlichen Konto ersichtlich. Nach diesem Zeitpunkt verfällt der gesamte Betrag an SwissAnwalt und kann nicht mehr
zurückgefordert werden.

Sobald die Anfrage in Bearbeitung ist, kann das einbezahlte Geld vom Fragesteller nicht mehr zurückverlangt werden.

Sollte sich nach Übernahme der Anfrage durch einen Rechtsanwalt und nach der Erstprüfung allerdings zeigen, dass der
Arbeitsaufwand für den Anwalt in keinem Verhältnis zum einbezahlten Betrag steht, kann dieser die Beantwortung der
Anfrage ablehnen. In diesem Fall wird dem Fragesteller das einbezahlte Guthaben zurückerstattet oder es wird ihm eine
Kosten-Offerte zur Beantwortung der Frage unterbreitet. Lehnt der Fragesteller die Offerte des Anwalts ab, wird ihm der
einbezahlte Betrag ebenfalls retourniert. Einen anderweitigen Anspruch hat der Fragesteller nicht.

Dieser Regelung in Ziff. 3.1.2 unterliegen auch alle Einzahlungen auf Ihrem Guthabenkonto vor Geltung dieser Fassung der
Nutzungsbedingungen, soweit sie dieser zugestimmt haben. Unberührt bleiben Ihre Rechte auf Widerruf nach Ziff. 7 und die
Regelung in Ziff. 11 dieser Geschäftsbedingungen.

3.1.3 Spezielle Anreiz-, Preis-, Mitgliedschafts- und sonstige Programme
SwissAnwalt behält sich das Recht vor, spezielle Anreiz-, Preis-, Mitgliedschafts- und sonstige Programme anzubieten.
Diese Programme können nach alleinigem Ermessen von SwissAnwalt auf einen bestimmten Kreis von Benutzern oder
Nicht-Benutzern beschränkt sein. Alle Aktionsboni und -gutschriften, die SwissAnwalt für die Nutzung der auf der
Webseite angebotenen Dienste (Antworten) auf Kundenkonten bereitstellt, bleiben Eigentum von SwissAnwalt (sie gehen
nicht in das Eigentum der Benutzer über und sind nicht erstattungsfähig) und sind - sofern auf der Webseite oder in
einer Email an den Benutzer nicht anders angegeben - 30 Tage lang gültig.

4. Ihre Fragen (Online-Rechtsberatung) an den Rechtsanwalt
Fragen können nur über die auf der Webseite vorgesehene Funktion an Rechtsanwälte gestellt werden.

Ihre Anfrage an einen Rechtsanwalt wird nur dann beantwortet, wenn Ihr Guthabenkonte den entsprechenden Betrag aufweist
und abgebucht werden kann.

5. Antworten (Online-Rechtsberatung) von Rechtsanwälten
Die Rechtsanwälte werden Ihre Fragen nur über die Webseite beantworten.

Bei Verwendung von E-Mail und anderen Formen der elektronischen Datenübermittlung geben Sie ausdrücklich Ihre
Zustimmung, dass sämtliche Beteiligten sich ebenfalls der gleichen Form der elektronischen Datenübermittlung bedienen
können.
Nehmen Sie bitte zur Kenntnis, dass zur Datensicherheit eine Verschlüsselung erforderlich ist. Sofern nicht ausdrücklich
Verschlüsselung verlangt wird, geben Sie bei Benützung von elektronischer Datenübermittlung Ihr Einverständnis, dass
sämtliche Kommunikation ohne Verschlüsselung erfolgt.

Beachten Sie, dass eine Antwort eines Rechtsanwalts auf Ihre Anfrage, keinesfalls eine individuelle Rechtsberatung durch
einen Rechtsanwalt ersetzt. Für eine solche ist die Webseite von SwissAnwalt nicht geeignet und auch nicht gedacht. Sie
müssen sich hierfür stets mit einer entsprechenden Berufsperson gesondert in Verbindung setzen. SwissAnwalt wird Ihre
Identität Dritten gegenüber - mit Ausnahme dem Rechtsanwalt - nicht offenbaren, es sei denn, SwissAnwalt ist hierzu
gesetzlich oder aufgrund einer behördlichen oder gerichtlichen Verfügung verpflichtet.

Der Rechtsanwalt und alle beteiligten Hilfspersonen, unterstehen dem anwaltlichen Berufsgeheimnis.

Wenn Sie eine Frage an einen Rechtsanwalt in der Online-Rechtsberatung elektronisch über die Webseite von SwissAnwalt
stellen, dann müssen Sie sich im Klaren darüber sein, dass das Internet niemals einen absolut 100%igen Schutz im
Zusammenhang mit dem Anwaltsgeheimnis bieten kann. Auch wenn sämtliche Informationen über eine sichere Verbindung
(https://) mit einer 256-Bit Verschlüsselung (je nach von Ihnen verwendetem Browser) kommuniziert werden.

SwissAnwalt und der Rechtsanwalt, welcher die Anfrage bearbeitet, sind jederzeit bemüht, das Anwalts- und
Berufsgeheimnis in jedem Fall einzuhalten. Garantiert werden kann dieses aufgrund der Kommunikationswege jedoch nicht.

Mit der Übernahme einer Anfrage von Ihnen durch einen Rechtsanwalt, entsteht zwischen Ihnen und dem Rechtsanwalt kein
Mandatsverhältnis, womit Sie allenfalls weiterhin zur Einhaltung sämtlicher Fristen selber verantwortlich sind.

Rechtsfragen in der Online Rechteberatung, werden in der Regel innert 48 Stunden beantwortet. Für Fragen, welche übers
Wochenende und über Feiertage beantwortet werden sollen, muss die Dringlichkeit der Antwort zwingend auf 8 Stunden
eingestellt werden, was sich selbstverständlich auch im Preis bemerkbar macht. Eine Bearbeitung übers Wochende oder über
Feiertage, ändert allerdings nichts an der Bearbeitungs-Dauer von - in der Regel - 48 Stunden.

Sämtliche Rechtsfragen in der «Online Rechtsberatung», werden von einem Rechtsanwalt mit entsprechendem Patent
beantwortet, welcher zudem in einem kantonalen Register als Rechtsanwalt eingetragen ist.

6. Online-Mandatsführung durch einen Rechtsanwalt
Auf der Webseite von SwissAnwalt können Sie einen Rechtsanwalt beauftragen, dass er Ihr Mandat direkt elektronisch und
über die Webseite von SwissAnwalt führt.

Wenn Sie ein Mandat elektronisch über die Webseite von SwissAnwalt führen, dann müssen Sie sich im Klaren darüber sein,
dass das Internet niemals einen absolut 100%igen Schutz im Zusammenhang mit dem Anwaltsgeheimnis bieten kann. Auch wenn
sämtliche relevanten Informationen über eine sichere Verbindung (https://) mit einer 256-Bit Verschlüsselung (je nach
von Ihnen verwendetem Browser) kommuniziert werden.

SwissAnwalt und der Rechtsanwalt, welcher das Mandat bearbeitet, sind jederzeit bemüht, das Anwalts- und Berufsgeheimnis
in jedem Fall einzuhalten. Garantiert werden kann dieses aufgrund der Kommunikationswege jedoch nicht.

Wenn Sie ein Mandat über SwissAnwalt führen möchten und solange sich dieses in der Vorprüfung resp. der Prüfung durch
einen Rechtsanwalt befindet, sind Sie zur Einhaltung sämtlicher Fristen selber verantwortlich. Beachten Sie in diesem
Zusammenhang, dass verpasste Fristen zum unwiderruflichen Verlust Ihrer sämtlichen Rechte führen kann.

Sobald Sie die durch den Rechtsanwalt allenfals festgesetzte Vorauszahlung auf ein Konto von SwissAnwalt überwiesen
haben und der Betrag auf dem Konto von SwissAnwalt gutgeschrieben wurde und Sie das Mandat für die Bearbeitung durch den
Rechtsanwalt aktiviert haben, gilt das Mandat als offiziell durch den Rechtsanwalt übernommen.

Ab diesem Zeitpunkt können Sie die ganze Kommunikation mit dem Anwalt direkt über Ihr persönliches Konto auf der
Webseite von SwissAnwalt führen.

7. Generieren einer Datenschutzerklärung mit dem Datenschutz-Generator
Für die kostenlose Datenschutzerklärung von SwissAnwalt wird keine Haftung für Richtigkeit, Vollständigkeit, Aktualität
oder Wirksamkeit des erzeugten Ergebnisses übernommen. Die Verwendung erfolgt ausdrücklich auf eigene Gefahr.

Die Datenschutzerklärung kann eine individuelle, anwaltliche Beratung im Einzelfall nicht ersetzen. Sie verpflichten
sich, den generierten Text der Datenschutzerklärung vollständig zu übernehmen. Anpassungen dürfen insofern vorgenommen
werden, als dass keine rechtlichen Ausführungen davon betroffen sind. Die Angabe der Quelle SwissAnwalt, ist in jedem
Fall inkl. dem vollständigen Link zur Webseite von SwissAnwalt zu übernehmen. Dies gilt auch dann, wenn Sie Anpassungen
im Text der Datenschutzerklärung vorgenommen haben.

Falls Sie die Quelle inkl. dem Link in der Datenschutzerklärung nicht oder nicht sichtbar auf Ihrer Seite angeben,
machen Sie sich einer Urheberrechtsverletzung schuldig.

Falls Sie die Quelle auf Ihrer Webseite nicht angeben möchten, dann können Sie von SwissAnwalt eine Lizenz zum Preis von
CHF 199.00 erwerben. In diesem Fall müssen Sie sich umgehend und vor der Aufschaltung der Datenschutzerklärung auf der
vorgesehenen Webseite beim Support von SwissAnwalt schriftlich per E-Mail melden und das Einverständnis zur Aufschaltung
ohne Quellenangabe einholen. Falls SwissAnwalt damit einverstanden ist, wird Ihnen die Erlaubnis dazu schriftlich
mitgeteilt. Die Rechnung für die Lizenzgebühr ist innert 10 Tagen zu bezahlen.

Falls Sie kein Einverständnis für die Aufschaltung der Datenschutzerklärung bei SwissAnwalt einholen und die Quellangabe
SwissAnwalt inkl. Link zur Webseite von SwissAnwalt weglassen, wird eine zusätzliche Abmahngebühr von CHF 650.00 zur
regulären Lizenzgebühr (Total CHF 849.00) fällig. Weitere Kosten, wie z.B. Betreibungs- oder Gerichtskosten für die
Durchsetzung der Urheberrechte, werden nach Aufwand zusätzlich in Rechnung gestellt.

SwissAnwalt kann bei einem Verstoss gegen obige Richtlinien, jederzeit die umgehende Löschung der Datenschutzerklärung
verlangen.

8. Haftungsregelungen für SwissAnwalt
Da bei Frage und Antwort durch den Rechtsanwalt ein Vertrag insoweit ausschliesslich zwischen Ihnen und dem Rechtsanwalt
zustande kommt und SwissAnwalt keinen Einfluss auf den Inhalt und die Beantwortung Ihrer Fragen nimmt, haftet
SwissAnwalt nicht für die Richtigkeit der Antworten von Rechtsanwälten.

Ebenfalls kommt bei der Online-Mandatsführung ausschliesslich ein Vertragsverhältnis mit dem beauftrageten Rechtsanwalt
zustande. SwissAnwalt übernimmt in diesem Zusammenhang lediglich das Finanzmanagement zwischen Ihnen und dem
beauftragten Rechtsanwalt.

Da SwissAnwalt nur eine Internetplattform zur Verfügung stellt, haftet SwissAnwalt grundsätzlich nicht für die Inhalte,
die von den Nutzern und den Rechtsanwälten über die Plattform ausgetauscht werden und/oder auf der Webseite einsehbar
sind. Dies gilt auch für ehrverletzende oder beleidigende Inhalte, strafrechtlich relevante Inhalte, oder bei der
Verletzung von Rechten Dritter (z.B. Urheberrechtsverletzungen, Markenverletzungen usw.) durch die Kommunikation und
Inhalte. Gleichfalls ist SwissAnwalt nicht verantwortlich für die Inhalte von Webseiten, auf die SwissAnwalt, die
Nutzer, die Rechtsanwälte durch Links oder auf andere Weise verweisen. SwissAnwalt wird jedoch entsprechende Inhalte von
der Webseite SwissAnwalt entfernen, wenn der konkrete Verdacht rechtswidrigen Inhalts besteht. Wenn Sie der Meinung
sind, dass bestimmte Inhalte rechtswidrig sind, dann senden Sie eine Email an support@swissanwalt.ch.

Für die Kommunikationsmittel, mittels deren der Nutzer mit SwissAnwalt und/oder den Rechtsanwälten kommuniziert, ist
SwissAnwalt nicht verantwortlich. Für einen Ausfall oder eine Störung dieser Kommunikationsmittel haftet SwissAnwalt
daher nicht, es sei denn, SwissAnwalt hat den Ausfall oder die Störung schuldhaft verursacht. SwissAnwalt behält sich
vor, den Zugang zur Webseite SwissAnwalt vorübergehend zu beschränken, wenn Arbeiten am System erforderlich sind oder
der Inhalt der Webseite geändert wird.

9. Haftungsbeschränkungen von SwissAnwalt
Soweit SwissAnwalt ausnahmsweise selbst haften sollte, ist die Haftung von SwissAnwalt auf Vorsatz und grobe
Fahrlässigkeit beschränkt. Im Falle der einfachen Fahrlässigkeit von SwissAnwalt haftet SwissAnwalt nur bei der
Verletzung von wesentlichen Vertragspflichten (solche Pflichten, auf deren Einhaltung durch SwissAnwalt der Nutzer
vertrauen durfte). Im Falle der Fahrlässigkeit ist die Haftung darüber hinaus auf den vorhersehbaren und
vertragstypischen Schaden beschränkt. Die gesetzliche Haftung von SwissAnwalt im Falle der Verletzung von Leib und Leben
bleibt unberührt.

10. Haftung der Rechtsanwälte
Wenn kein Vertrag zwischen Ihnen und einem Rechtsanwalt zustande kommt, haftet der Rechtsanwalt nach den gesetzlichen
Bestimmungen nur in Ausnahmefällen. Kommt ein Vertrag mit einem Rechtsanwalt zustande, so haftet dieser nach den
gesetzlichen Bestimmungen. Sie können im Zusammenhang mit der Online-Rechtsberatung keine generelle Auskunft erwarten,
die auf Sie zugeschnitten ist, und auf die Sie sich vollumfänglich verlassen können. Holen Sie in diesem Fall stets bei
konkreten Problemen den Rat von fachlich versierten Berufspersonen ausserhalb der Webseite ein oder lassen Sie Ihren
Fall über die Online-Mandatsführung lösen.

11. Ihre Verpflichtungen
Sie dürfen durch Ihre Fragen und Beiträge nicht gegen gesetzliche Bestimmungen, gegen die guten Sitten und diese allg.
Geschäftsbedingungen verstossen. Insbesondere dürfen Sie keine Rechte Dritter (z.B. Persönlichkeitsrechte, Markenrechte,
Urheberrechte) verletzen, keine verfassungswidrigen, pornografischen und jugendgefährdende Inhalte verbreiten und keine
Werbung betreiben, auch nicht durch Verlinkung mit anderen Webseiten. Ferner sind insbesondere ehrverletzende,
verleumderische, herabwürdigende, rassistische Inhalte und solche Inhalte, die lediglich zum Zweck der Verbreitung
weltanschaulicher, politischer oder religiöser Ziele dienen, unzulässig.

Sie haben in angemessenem Umfang dafür zu sorgen, dass Sie durch Ihre Kommunikation weder die Leistungen von SwissAnwalt
noch die Leistungen der Rechtsanwälte oder anderer Dritter durch Viren, Trojaner, Internet-Würmer oder dergleichen
beeinträchtigen.

Das vorsätzliche Umgehen Ihrer Zahlungsverpflichtungen, z.B. durch direkte Zahlungen an Rechtsanwälte für die
Beantwortung von über SwissAnwalt gestellte Fragen (Online-Rechtsberatung) oder im Zusammenhang mit der
Online-Mandatsführung, ist ebenso wie das Manipulieren der Webseite und das Verändern von deren Inhalten unzulässig.

Sie dürfen Sie keine Zahlungen ausserhalb der Plattform von SwissAnwalt an Dritte oder den Rechtsanwalt leisten im
Zusammenhang mit Dienstleistungangeboten von SwissAnwalt.

Verletzen Sie diese Pflichten schuldhaft, so sind Sie verpflichtet, SwissAnwalt von allen hieraus geltend gemachten
Ansprüchen resp. Ansprüchen Dritter, freizuhalten und SwissAnwalt alle hieraus entstehende Schäden, einschliesslich
angemessner Rechtsverteidigungskosten, zu ersetzen. Sollten aufgrund Ihrer Inhalte Ansprüche gegen SwissAnwalt geltend
gemacht werden, werden wir Sie über Email unverzüglich unterrichten.

Unabhängig von Ihrem Verschulden ist SwissAnwalt berechtigt, rechtswidrige oder gegen diese Bedingungen verstossende
Inhalte ganz oder teilweise zu löschen.

12. Sperrung, Kündigung
SwissAnwalt ist berechtigt, Ihren Zugang zu SwissAnwalt zu sperren, wenn Sie gegen Ihre wesentlichen Pflichten aus
diesen Nutzungsbedingungen oder gegen rechtliche Bestimmungen verstossen. SwissAnwalt kann auch zu einer Sperrung
berechtigt sein, wenn Sie fortlaufend Antworten der Rechtsanwälte nicht akzeptieren wollen und SwissAnwalt hieraus den
Schluss ziehen muss, dass Sie grundsätzlich nicht zur Zahlung für eine Antwort bereit sind.

SwissAnwalt ist ferner berechtigt, diese Vereinbarung mit Ihnen aus wichtigem Grund mit sofortiger Wirkung zu kündigen.
Ein wichtiger Grund kann auch dann vorliegen, wenn Sie einen zur Sperrung berechtigenden Grund gegeben haben.

Sie sind berechtigt, jederzeit ohne Angaben von Gründen durch Mitteilung an den support@swissanwalt.ch Ihren Account zu
kündigen. SwissAnwalt ist berechtigt, Ihnen auch ohne Grund mit einer Frist von einem Monat zu kündigen.

Ausnahme dazu bildet lediglich die Kündigung zur Unzeit.

Verbleibende Guthaben werden Ihnen im Fall der Kündigung - sofern diese nicht bereits verfallen sind (Ziff. 3.1.2) -
binnen 30 Tagen ausbezahlt. Durch eine Kündigung werden Ihre bereits bestehenden Verpflichtungen aus Ihren
Rechtsverhältnissen mit Rechtsanwälten nicht berührt.

13. Weitere Leistungen
Neben den in diesen Bedingungen beschriebenen Leistungen bietet SwissAnwalt weitere Leistungen an, deren Bedingungen und
Funktion sich aus der Webseite SwissAnwalt ergeben. Diese Leistungen können von SwissAnwalt jederzeit geändert oder
eingestellt werden.

14. Weitere bindende Bestimmungen
Ergänzend zu diesen Bedingungen gelten alle weitere Bestimmungen, die ihrer Natur nach bindend sind. Hierzu gehören
insbesondere die Datenschutzbestimmungen (Datenschutz und Sicherheit) und die Teilnahmebedingungen für
Email-Empfehlungen von neuen Nutzern und SwissAnwalt (Empfehlungsprogramm).

15. Änderung der Bestimmungen
SwissAnwalt ist berechtigt, die Bestimmungen dieser Bedingungen sowie der weiteren rechtlich verbindlicher Inhalte der
Webseite von SwissAnwalt, jederzeit zu ändern. Erfolgt jedoch eine Änderung zu Ihren Lasten, so werden wir Sie hierauf
zuvor durch eine Email oder durch einen deutlich hervorgehobenen Hinweis auf der Webseite hinweisen. Sie haben die
Möglichkeit, dieser Änderung durch Email an support@swissanwalt.ch oder schriftlich an die im Impressum benannte Adresse
von SwissAnwalt, binnen 30 Tagen seit Zugang der Änderungsmitteilung zu widersprechen. Widersprechen Sie nicht
rechtzeitig oder nutzen Sie die Leistungen von SwissAnwalt nach Erhalt der Änderungsmitteilung durch erneutes Einloggen
mittels Nutzernamen und Passwort, gelten die geänderten Bestimmungen als akzeptiert. Hierauf wird SwissAnwalt Sie
gesondert in der Änderungsmitteilung hinweisen.

SwissAnwalt weist darauf hin, dass durch Änderungen des geltenden Rechts, gerichtliche oder behördliche Verfügungen eine
sofortige Änderung der Bestimmungen erforderlich sein kann. In diesem Fall behält sich SwissAnwalt vor, die Änderungen
mit sofortiger Wirkung umzusetzen. Hierauf wird SwissAnwalt Sie unverzüglich hinweisen. Sie können Ihren Account dann
unverzüglich kündigen.

Widersprechen Sie der Änderung rechtzeitig, so bleibt das Recht von SwissAnwalt, Ihren Account durch eine Email mit
einer Frist von 30 Tagen zu kündigen, unberührt. Ein Guthaben wird Ihnen dann binnen 30 Tagen zurückbezahlt. Durch die
Kündigung werden Ihre bereits bestehende Verpflichtungen aus Rechtsverhältnissen mit Rechtsanwälten nicht berührt.

16. Kommunikation
Wenn Sie mit uns und Rechtsanwälten per Email kommunizieren, erfolgt dies auf elektronischem und nicht verschlüsseltem
Weg. Beachten Sie, dass deshalb nicht ausgeschlossen werden kann, dass Mitteilungen verändert oder auf dem
Übertragungsweg gelöscht werden können. Um Nachrichten von SwissAnwalt über Email rechtzeitig zur Kenntnis nehmen zu
können, müssen Sie sicherstellen, dass Ihr Spamfilter Nachrichten mit der Domain swissanwalt.ch nicht zurückhält.

17. Übertragung dieses Vertragsverhältnisses durch SwissAnwalt
SwissAnwalt ist berechtigt, diesen Vertrag auf ein ihr verbundenes Unternehmen (Unternehmen, an dem SwissAnwalt
unmittelbar oder mittelbar die Mehrheit der Geschäftsanteile oder die Mehrheit der Stimmrechte hat oder Unternehmen,
welche mittelbar oder unmittelbar die Mehrheit der Geschäftsanteile oder der Stimmrechte an SwissAnwalt hat) zu
übertragen. Sie sind berechtigt, dieses Vertragsverhältnis ohne Einhaltung einer Frist durch eine Mitteilung an
support@swissanwalt.ch zu kündigen, wenn es zu einer solchen Übertragung kommt. Wir werden Ihnen eine solche Übertragung
durch Email oder durch einen Hinweis auf der Webseite mitteilen.

18. Anwendbares Recht
Dieser Vertrag untersteht schweizerischem Recht. Ausschliesslicher Gerichtsstand ist Arbon, Kanton Thurgau oder
wahlweise der Sitz des beauftragten Rechtsanwalts.

19. Unwirksame Klauseln
Sollten einzelne oder mehrere Klauseln dieser Bedingungen oder der weiteren vertraglichen Bestimmungen unwirksam sein
oder werden, so berührt dies die Wirksamkeit Ihres Rechtsverhältnisses mit SwissAnwalt im Übrigen nicht.



Neukirch (Egnach), 1. März 2019

AGB, Version 1.32 -->

				<footer class="modal-footer">
					<slot name="footer">
						<button type="button" class="" @click="$emit('close')" aria-label="Close modal">
							<slot name="button">
								OK
							</slot>
						</button>
					</slot>
				</footer>
			</div>
		</div>
	</transition>
</template>

<script>
	import { ref } from 'vue';

	let language = ref('EN');
	let showLanguagesVal = ref(false);

	export default {
		name: 'Modal',
		props: {
			showLanguages: Boolean,
		},
		methods: {
			getLanguage(lang) {
				language.value = lang == 'DE' ? 'DE' : 'EN';
			},
		},
		data() {
			return {
				showLanguagesVal: this.showLanguages,
				language,
			};
		},
		mounted() {},
		setup() {},
	};
</script>
