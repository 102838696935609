<template>
	<!-- Hard-coded pId, passsed to component -->
	<CertificationBlock v-if="idVal == 1" :pId="1" :isCard="false" :isShiftLogo="true">
		<template v-slot:logo>
			<font-awesome-icon :icon="['fas', 'sitemap']" style="font-size: 2.5em;" />
			<!-- https://fontawesome.com/icons/wrench?style=solid -->
			<!-- https://fontawesome.com/icons/layer-group?style=solid -->
		</template>
		<template v-slot:title>
			Salesforce & Enterprise Architecture
		</template>
		<template v-slot:date>
			Lead architect for many large and complex Salesforce implementations. I have been working with nearly every Salesforce product in multi-cloud and multi-org implementations. Experience on Salesforce projects across the full
			<TooltipAbbreviation :short="'SDLC'" :long="'Software Development Lifecycle'" /> in multiple roles.
		</template>
		<template v-slot:details>
			I am a trusted expert in setting up green- and brownfield implementations. I have strong knowledge in leveraging standard Salesforce features to their fullest potential, and proven expertise especially for defining complex custom architectures
			<!--that
			are stable and flexible.-->that provide a stable long-term foundation while being flexible for the future. As part of my projects I am
			<!--typically-->
			in close contact with Enterprise Architects, helping them to build a strategy based on healthy core principles, supporting the company's long-term vision, and recommend and implement solutions even outside of the Salesforce product portfolio, such as systems for
			<TooltipAbbreviation :short="'MDM'" :long="'Master Data Management'" /> or <TooltipAbbreviation :short="'ESB'" :long="'Enterprise Service Bus'" />. I've been working for global companies with 15.000 to 150.000 employees, focusing on large enterprises with complex, strategic
			implementation projects.
		</template>
	</CertificationBlock>
	<!-- Trust. CI/CD. etc. - search for my "TA Skills" overview and get the most important ones. Customer focus. elementDesktop Work Ethic. Flexibility, firefighter, always doing what matters most. Precise, detailed. Multi-facetted: Project Mgr, devloper, admin etc. Broad skillset. Dazu noch Professional Skills (Communication, Team Work/Team Player throughout my projects, Creativity, Project Mgmt) -->
	<!-- Complex integration bidirectional
		Security, Special considerations
		Existing environment
		Enterprise systems eg AD
		Enterprise tools eg ESB


		Born with Classic, Lightning

		admin work etc helps me to become a good architect

Copied from https://trailhead.salesforce.com/help?article=Salesforce-Certified-Technical-Architect-Exam-Guide - so be careful
Various SF applications and technologies lead architect multiple complex deployments guiding development team on appropriate use of platform technology identify and mitigate technical risks across teh architecture globalization considerations
		object-oriented design patterns platform-specific design patterns and limits Ability to identify development-related risks, considerations, and limits for the platform Understanding of and ability to architect a solution to address security
		complexities, mechanisms, and capabilities on the Lightning Platform as part of a functional security model Awareness of large data volume considerations, risks, and mitigation strategies Awareness of general mobile solutions and architecture and
		understanding of on-platform mobile solutions and considerations Evaluation of Salesforce platform capabilities, constraints, and limits for specificcomplex scenario (eg russian DB), Apttus CPQ integration etc. Single/multi org; org splits & merge
		design and justify an end-to-end identity management solution Given a set of business requirements, describe the appropriate combination of declarative and programmatic functionality within a solution. Given a scenario, describe the benefits,
		considerations, and trade-offs of incorporating external applications into an optimal solution architecture. Admin experience -->
	<CertificationBlock v-if="idVal == 2" :pId="2" :isShiftLogo="true">
		<template v-slot:logo>
			<font-awesome-icon :icon="['fas', 'crown']" style="font-size: 2.5em;" />
		</template>
		<template v-slot:title>
			Architect, Developer, Business Analyst, Admin, Project Manager
		</template>
		<template v-slot:date>
			Wearing many hats throughout my professional life, giving me a unique and broad perspective. I'm able to put myself into the shoe's of anyone working with Salesforce and IT systems in general.
		</template>
		<template v-slot:details>
			My past role as a Lead Business Analyst helps me understand business requirements, processes and user pain points - next to technical challenges. I have been supporting end-users as a Lead Admin<!--, getting to know their needs and wants-->. I am an expert in theory and practice as I
			have been able to see behind every curtain
			<!--not only know how to implement something in theory, but also in practice:-->
			implementing challenging customization throughout the Salesforce platform and hundreds of Apex, Visualforce or Lightning Components as a Lead Developer.<!-- me to understand the details and plan accordingly.-->
			I have been in the area of enterprise and software architecture for over 8 years, building sophisticated integrations with various common enterprise systems. I provide a wide range of development expertise across the whole
			<TooltipAbbreviation :short="'SDLC'" :long="'Software Development Lifecycle'" /> as well as Architecture & programming patterns.
			<!--in various app and web languages, such as Apex, JavaScript, Visualforce, SAP ABAP, Python, Java, PHP or C-->
			I built multiple design frameworks within Salesforce for managing automation, testing, bulk data and more for large enterprises. I am fluent in various programming frameworks and languages.
		</template>
	</CertificationBlock>
	<!-- Development Web markup languages: HTML/CSS Programming languages: Apex, Python, Javascript, C, C#, PHP, various JavaScript frameworks (Vue), Apache HTTP server, Microsoft IIS SQL, SOQL/SOSL Development Environments Force.com IDE, MavensMate, Developer -->
	<!-- Salesforce automation is a pet peeve of mine -->
	<!-- Multiple ISV packages: ITB DataLoadVizArt, ITB TemplateVizArt, ,  TODO: See Hilti Org. See metadata downloads for other customers, eg remedyforce-->
	<!-- Loader Development Life Cycle Git, Jenkins, SourceTree, Force.com Migration Toolkit Basic skills Programming and Markup Languages SAP ABAP Databases SQL Operating Systems Linux O ce Applications Microsoft O ce Access Development Life Cycle Apache ANT
			Salesforce Tools Lightning App Builder Salesforce Apps Clicktools -->
	<!-- <CertificationBlock v-if="idVal == 1" :isShiftLogo="true">
		<template v-slot:logo>
			<font-awesome-icon :icon="['fas', 'envelope']" style="font-size: 2.5em;" />
		</template>
		<template v-slot:title>
			Security
		</template>
		<template v-slot:date>
			Sharing, access, own tool, OAuth, IDM Setting up functional role models Extensive understanding of standard and custom security mechanisms Internal and external
		</template>
	</CertificationBlock> -->
	<CertificationBlock v-if="idVal == 3" :pId="3" :isShiftLogo="true">
		<template v-slot:logo>
			<img src="../assets/integration.svg" width="60" alt="Icon Integration" />
		</template>
		<template v-slot:title>
			Integration & Data Management
		</template>
		<template v-slot:date>
			I have been the Lead Integration Architect for various enterprise projects in the areas of managing customers, service, products & material, employees and visitors as well as business intelligence and the internet of things. I have been responsible for complex enterprise integrations and
			defined and built them from the ground up. Based on my background and various projects I have a very good knowledge especially in the area of SAP ERP integration.
		</template>
		<template v-slot:details>
			I have evaluated and implemented multiple integration solutions and platforms myself and guided integration experts connecting enterprise systems with Salesforce (<TooltipAbbreviation :short="'ETL'" :long="'Extract, Transform, Load'" />,
			<TooltipAbbreviation :short="'ESB'" :long="'Enterprise Service Bus'" />, <TooltipAbbreviation :short="'EDA'" :long="'Event-Driven Architecture'" />). In customer projects and side projects I have been able to prove my extensive knowledge about many different Salesforce
			<TooltipAbbreviation :short="'APIs'" :long="'Application Programming Interface'" />, with a special focus on Metadata/Tooling <TooltipAbbreviation :short="'API'" :long="'Application Programming Interface'" />.

			<!-- Development Life Cycle ggf unter Development knowledge Middleware ETL integration patterns
			ETL, Integration, Master Data, Migration+considerations+design, Data Models, Data Governance Given a
			scenario, describe the platform architecture considerations, platform impact and optimization methods used when working with large data volumes. Given a scenario, describe data modeling concepts and implications of database design and modeling. Given a
			scenario, determine the data migration strategy, considerations, and appropriate tools to use.
			Given a set of requirements, recommend the appropriate enterprise integration landscape and describe associated risks, trade-offs, and business and technical considerations within a customer environment.
			Given a scenario, describe the capabilities of the appropriate technology and justify their use as part of the overall integration architecture.
			Given a scenario, recommend and justify the appropriate integration strategy and the use of common integration patterns.
			Given a scenario, recommend and justify the appropriate platform-specific integration technology used to integrate with external systems and describe the capabilities, limitations and trade-offs. -->
		</template>
	</CertificationBlock>
	<CertificationBlock v-if="idVal == 4" :pId="4" :isShiftLogo="true">
		<template v-slot:logo>
			<img src="../assets/cicd.svg" width="50" alt="Icon CI/CD" />
		</template>
		<template v-slot:title>
			Continuous Integration & Deployment (CI/CD)
		</template>
		<template v-slot:date
			>I have been setting up the groundwork for Salesforce-centric deployment by defining cross-system processes and implementing complex <TooltipAbbreviation :short="'CI/CD'" :long="'Continuous Integration and Deployment'" /> frameworks based on Salesforce DX methodology and other
			integration tools.</template
		>
		<!-- Server-side/backend setup etc. SFDX, Jenkins, Bamboo, Copado, ... Given a scenario, identify the project risks and mitigation strategies. Given a customer project environment and development methodology, identify the technical considerations, risks,
		and impact on the technical work streams. Given a scenario, recommend an appropriate comprehensive test strategy and discuss how testing mitigates any project risks. Given a scenario, describe the considerations, stakeholders, and impact of decisions
		around a technical solution relative to customer project governance. Given a scenario, describe the platform tools, use cases, limitations, and best practices for environment management. Describe the common tools, benefits, and rationale for using
		source control and continuous integration for release management. -->
		<template v-slot:details
			>From defining an Integration and Deployment framework over implementing backend code, developing scripts and <TooltipAbbreviation :short="'CI/CD'" :long="'Continuous Integration and Deployment'" /> tools to release management I have lead various engagements and built solutions from
			scratch that take care of planning, packaging, delivering, deploying and testing development artifacts.</template
		>
	</CertificationBlock>
	<CertificationBlock v-if="idVal == 5" :pId="5" :isShiftLogo="true">
		<template v-slot:logo>
			<font-awesome-icon :icon="['fas', 'users']" style="font-size: 2.5em;" />
			<!-- https://fontawesome.com/icons/users?style=solid -->
		</template>
		<template v-slot:title>
			Customer-focused Team Player
		</template>
		<template v-slot:date>
			I've been doing internal consultancy for four years and external consultancy for an additional four years. Both have one winning ingredient in common: Teamwork. With my focus on customer success and trust as a core value I am always motivated to go the extra mile.
		</template>
		<template v-slot:details>
			I have been leading teams as a Project Manager, a Technical Architect, a Developer and an Admin and I know: Every project is always a team effort. I am a strong, independent player but I cannot overestimate the contribution and sheer unlimited value of great teamwork and collaboration. I
			have been involved in various agile projects and I am a Scrum Master.
		</template>
	</CertificationBlock>

	<!-- <CertificationBlock v-if="idVal == 1" :isShiftLogo="true">
	<template v-slot:logo>
		<font-awesome-icon :icon="['fas', 'envelope']" style="font-size: 2.5em;" />
	</template>
	<template v-slot:title>
		Communications
	</template>
	<template v-slot:date>
		Articulate the benefits, limitations, considerations, and design choices for a solution architecture and handle objections related to the design.Demonstrate how visualization and documentation tools can be used to articulate the technical solution.
		Demonstrate the ability to handle unexpected roadblocks and determine the appropriate next steps.
	</template>
</CertificationBlock> -->
	<CertificationBlock v-if="idVal == 6" :pId="6" :isShiftLogo="true" :hasNoBack="true">
		<template v-slot:logo>
			<font-awesome-icon :icon="['fas', 'globe-americas']" style="font-size: 2.5em;" />
		</template>
		<template v-slot:title>
			Language: English
		</template>
		<template v-slot:date>
			Full professional proficiency
		</template>
	</CertificationBlock>
	<CertificationBlock v-if="idVal == 7" :pId="7" :isShiftLogo="true" :hasNoBack="true">
		<template v-slot:logo>
			<font-awesome-icon :icon="['fas', 'globe-americas']" style="font-size: 2.5em;" />
		</template>
		<template v-slot:title>
			Language: German
		</template>
		<template v-slot:date>
			Native speaker
		</template>
	</CertificationBlock>
</template>

<script>
	import CertificationBlock from './CertificationBlock';
	import TooltipAbbreviation from './TooltipAbbreviation';

	export default {
		name: 'Skills',
		props: {
			id: Number,
		},
		components: {
			CertificationBlock,
			TooltipAbbreviation,
		},
		methods: {},
		data() {
			return {
				idVal: this.id,
			};
		},
		mounted() {},
		setup() {},
	};
</script>
