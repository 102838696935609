<template>
	<div class="custom-tooltip custom-tooltip1">
		<span class="custom-tooltip-placeholder">
			<slot name="item"></slot>
		</span>
		<span class="custom-tooltiptext" :style="style">
			<slot name="text"></slot>
		</span>
	</div>
</template>

<script>
	export default {
		name: 'Header',
		props: {
			shift: Number,
		},
		computed: {
			style() {
				return 'margin-left: ' + this.shift + 'px';
			},
		},
		data() {
			return { shift: this.shift };
		},
		mounted() {},
		setup() {},
	};
</script>
