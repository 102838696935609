<template>
	<!-- CSS Grid or Flexbox does not work, as it will make all items on the same row have the same height - so, if you expand one, the other one on the same row will be expanded as well. I can use JS to prevent it from fully expanding, but then it will just have whitespace below. Instead I want items from below to fill this white space -->
	<!--<div style="display: grid; grid-auto-rows: 0fr; grid-gap: 3rem; grid-template-columns: repeat(2, 48%); width: 100%">-->
	<span :id="'project-' + pIdVal" style="display: block; position: relative; top: -4em; visibility: hidden;"></span>
	<div class="projects-item">
		<div class="certification certification-left project" data-aos="fade-in" data-aos-offset="-500" data-aos-delay="50" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true" data-aos-anchor-placement="top-center" @click="toggle(pIdVal)" ref="el">
			<div class="accordion">
				<div class="flip-info-project flip-info-project-expand"><font-awesome-icon :icon="['fas', 'info-circle']" class="icon-small-dark" /></div>
				<div class="flip-info-project flip-info-project-collapse"><font-awesome-icon :icon="['fas', 'chevron-left']" class="icon-small-dark" /></div>
				<div class="project-logo">
					<template v-if="!isGenericVal">
						<slot name="logo"></slot>
					</template>

					<template v-if="isGenericVal">
						<div class="project-logo-generic">
							<div class="project-logo-generic-circle">
								<span class="project-logo-generic-title">
									<slot name="logo"></slot>
								</span>
							</div>
						</div>
					</template>
				</div>
				<div class="project-header">
					<div class="project-description">
						<span> <slot name="company"></slot> </span><br />
						<span><slot name="from"></slot>—<slot name="to"></slot></span>
					</div>
				</div>
				<hr class="project-sep" />
				<div class="project-content-description">
					<slot name="description"></slot>
				</div>
			</div>

			<div class="project-content-main">
				<span class="project-content-headline">Responsibilities</span>
				<ul>
					<slot name="responsibilities"></slot>
				</ul>
				<span class="project-content-headline">Achievements</span>
				<ul>
					<slot name="achievements"></slot>
				</ul>
				<span class="project-content-headline">Salesforce Products</span>
				<div class="project-content-products">
					<template v-for="(value, index) of productsVal">
						<span :class="'product product-' + value">{{ productNames[value] }}</span>
					</template>
				</div>

				<span v-if="otherProductsVal" class="project-content-headline">Other Products</span>
				<div class="project-content-products">
					<template v-for="(value, index) of otherProductsVal">
						<span :class="'product product-other'">{{ value }}</span>
					</template>
				</div>
			</div>
		</div>
	</div>

	<!-- Option 2: Auto-layout by using flex. Unfortunately, we can't use this for a toggleable layout, as all items in a row will be full height even if not expanded, so there will be empty white space -->
	<!-- <div class="certification certification-left project" data-aos="fade-in" data-aos-offset="-500" data-aos-delay="50" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true" data-aos-anchor-placement="top-center">
		<div @click="toggle" ref="el">
			<div class="project-logo">
				<template v-if="!isGenericVal">
					<slot name="logo"></slot>
				</template>

				<template v-if="isGenericVal">
					<div class="project-logo-generic">
						<div class="project-logo-generic-circle">
							<span class="project-logo-generic-title">
								<slot name="logo"></slot>
							</span>
						</div>
					</div>
				</template>
			</div>
			<div class="project-header">
				<div class="project-description">
					<span> <slot name="company"></slot> </span><br />
					<span><slot name="from"></slot>—<slot name="to"></slot></span>
				</div>
			</div>
			<hr class="project-sep" />
			<div class="project-content-description">
				<slot name="description"></slot>
			</div>
		</div>

		<div class="project-content-main">
			<span class="project-content-headline">Responsibilities</span>
			<ul>
				<slot name="responsibilities"></slot>
			</ul>
			<span class="project-content-headline">Achievements</span>
			<ul>
				<slot name="achievements"></slot>
			</ul>
			<span class="project-content-headline">Salesforce Products</span>
			<div class="project-content-products">
				<template v-for="(value, index) of productsVal">
					<span :class="'product product-' + value">{{ productNames[value] }}</span>
				</template>
			</div>

			<span v-if="otherProductsVal" class="project-content-headline">Other Products</span>
			<div class="project-content-products">
				<template v-for="(value, index) of otherProductsVal">
					<span :class="'product product-other'">{{ value }}</span>
				</template>
			</div>
		</div>
	</div> -->
</template>

<!--<template>
	<div class="certification certification-left project" data-aos="fade-in" data-aos-offset="-500" data-aos-delay="50" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true" data-aos-anchor-placement="top-center">
		<div class="project-logo">
			<template v-if="!isGenericVal">
				<slot name="logo"></slot>
			</template>

			<template v-if="isGenericVal">
				<div class="project-logo-generic">
					<div class="project-logo-generic-circle">
						<span class="project-logo-generic-title">
							<slot name="logo"></slot>
						</span>
					</div>
				</div>
			</template>
		</div>
		<div class="project-header">
			<div class="project-description">
				<span> <slot name="company"></slot> </span><br />
				<span><slot name="from"></slot>—<slot name="to"></slot></span>
			</div>
		</div>
		<hr class="project-sep" />
		<div class="project-content-description">
			<slot name="description"></slot>
		</div>
		<span class="project-content-headline">Responsibilities</span>
		<ul>
			<slot name="responsibilities"></slot>
		</ul>
		<span class="project-content-headline">Achievements</span>
		<ul>
			<slot name="achievements"></slot>
		</ul>
		<span class="project-content-headline">Salesforce Products</span>
		<div class="project-content-products">
			<template v-for="(value, index) of productsVal">
				<span :class="'product product-' + value">{{ productNames[value] }}</span>
			</template>
		</div>

		<span v-if="otherProductsVal" class="project-content-headline">Other Products</span>
		<div class="project-content-products">
			<template v-for="(value, index) of otherProductsVal">
				<span :class="'product product-other'">{{ value }}</span>
			</template>
		</div>
	</div>
</template>-->

<script>
	import { ref } from 'vue';

	let productNames = {
		sales: 'Sales Cloud',
		service: 'Service Cloud',
		marketing: 'Marketing Cloud',
		community: 'Experience Cloud',
		platform: 'Platform',
		fsl: 'Field Service',
		tableau: 'Tableau CRM',
		mulesoft: 'Mulesoft',
		maps: 'Maps',
		heroku: 'Heroku',
		pardot: 'Pardot',
		iot: 'IoT Cloud',
		data: 'Data.com',
	};

	let isToggled = ref(false);

	export default {
		name: 'ProjectBlock',
		props: {
			isGeneric: Boolean,
			products: Array,
			otherProducts: Array,
			pId: Number,
		},
		methods: {
			toggle(id) {
				/* Option 1: Expandeable elements, where all elements in the same row are expanded/collapsed at the same time */
				// @click on acordion
				/*
				// Via ref="X" you can use the normal JS functions such as .children, .nextSibling, .parentNode etc.
				let el2 = this.$refs.el.nextSibling;
				// console.log('1', el2, el2.style.maxHeight);
				// console.log('ELEM Y ', this.$refs.el.parentElement.getBoundingClientRect().y, this.$refs.el.parentElement);
				// // console.log('PARENT ', this.$refs.el.parentElement);
				// // console.log('PREV SIBL', el2.parentElement.previousElementSibling);
				// console.log('PREV SIBL Y', this.$refs.el.parentElement.previousElementSibling.getBoundingClientRect().y, this.$refs.el.parentElement.previousElementSibling);
				// console.log('NEXT SIBL Y', el2.parentElement.nextElementSibling.getBoundingClientRect().y, parentElement.nextElementSibling);

				// parentElement not parent to ignore comments or whitespace
				let el = this.$refs.el.parentElement;
				let prevEl = this.$refs.el.parentElement.previousElementSibling;
				let nextEl = this.$refs.el.parentElement.nextElementSibling;

				if (el2.style.maxHeight == 0 || el2.style.maxHeight == '0px') {
					console.log('Expanding');
					el2.style.maxHeight = el2.scrollHeight + 'px';
					el2.style.transition = 'max-height 1s ease-in-out';
				} else {
					console.log('Collapsing', el2.style.maxHeight);
					el2.style.maxHeight = '0';
					el2.style.transition = 'max-height 1s ease-in-out';
				}

				// Check if there are other elements on the ame row - if yes, toggle them as well (together
				// We determine this by checking if there is an other element with the same y coordinates (i.e. same vertical starting point)
				if (prevEl != null && el.getBoundingClientRect().y == prevEl.getBoundingClientRect().y) {
					console.log('SAME ROW AS PREVIOUS');

					// Get content element we want to expand
					let prevElContent = prevEl.firstChild.nextElementSibling;

					if (prevElContent.style.maxHeight == 0 || prevElContent.style.maxHeight == '0px') {
						console.log('Expanding');
						// prevElContent.style.maxHeight = prevElContent.scrollHeight + 'px';
						prevElContent.style.transition = 'max-height 1s ease-in-out';
					} else {
						console.log('Collapsing', prevElContent.style.maxHeight);
						prevElContent.style.maxHeight = '0';
						prevElContent.style.transition = 'max-height 1s ease-in-out';
					}
				}
				if (nextEl != null && el.getBoundingClientRect().y == nextEl.getBoundingClientRect().y) {
					console.log('SAME ROW AS NEXT');

					let nextElContent = nextEl.firstChild.nextElementSibling;

					if (nextElContent.style.maxHeight == 0 || nextElContent.style.maxHeight == '0px') {
						console.log('Expanding');
						// nextElContent.style.maxHeight = nextElContent.scrollHeight + 'px';
						console.log('f', nextEl.firstChild.scrollHeight)
						console.log('f', nextEl)
						nextEl.style.maxHeight = nextEl.firstChild.scrollHeight + 'px';
						nextElContent.style.transition = 'max-height 1s ease-in-out';
					} else {
						console.log('Collapsing', nextElContent.style.maxHeight);
						nextElContent.style.maxHeight = '0';
						nextElContent.style.transition = 'max-height 1s ease-in-out';
					}
				}

				console.log(el2.getBoundingClientRect());
				// 				var rect1 = $('.first')[0].getBoundingClientRect();
				// var rect2 = $('.second')[0].getBoundingClientRect();
				// var overlaps = rect1.top <= rect2.bottom && rect2.top <= rect1.bottom;

				// var coll = document.getElementsByClassName('collapsible');
				// var i;

				// for (i = 0; i < coll.length; i++) {
				// 	coll[i].addEventListener('click', function() {
				// 		this.classList.toggle('active');
				// 		var content = this.nextElementSibling;
				// 		if (content.style.maxHeight) {
				// 			content.style.maxHeight = null;
				// 		} else {
				// 			content.style.maxHeight = content.scrollHeight + 'px';
				// 		}
				// 	});
				// }

				// this.classList.toggle('active');
				// var content = this.nextElementSibling;
				// if (content.style.maxHeight) {
				// 	content.style.maxHeight = null;
				// } else {
				// 	content.style.maxHeight = content.scrollHeight + 'px';
				// }
				*/

				let el = this.$refs.el.firstElementChild;

				var panel = el.nextElementSibling;
				if (panel.style.maxHeight) {
					panel.style.maxHeight = null;
				} else {
					panel.style.maxHeight = panel.scrollHeight + 'px';
				}

				let collapse = el.children[0];
				let expand = el.children[1];

				if (panel.style.maxHeight) {
					collapse.style.display = 'none';
					expand.style.display = 'inline';
				} else {
					collapse.style.display = 'inline';
					expand.style.display = 'none';
				}
				document.getElementById('project-' + id).scrollIntoView();
			},
		},
		data() {
			return {
				isGenericVal: this.isGeneric,
				productsVal: this.products,
				otherProductsVal: this.otherProducts,
				pIdVal: this.pId,
				productNames,
				isToggled,
			};
		},
		mounted() {},
		setup() {},
	};
</script>
