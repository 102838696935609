<template>
	<!-- ontouchstart="this.classList.toggle('hover');" = flip on touch - disabled as too easy to trigger. It also triggers when scrolling or just tapping but not releasing.. -->
	<!-- Not using @touchend="touchEnd()" as it only works on touch devices not desktop; but we want desktop click to trigger as well -->
	<!-- OPTION 1 -->
	<!-- <div class="flip-container" @click="touchEnd()" @touchstart="touchStart()" @touchmove.passive="touchMove()" ref="el">
		<div class="flipper">
			<div class="front"> -->
	<!-- <div class="flip-card">
											<div class="flip-card-inner">


												<div class="flip-card-front"> -->
	<!-- Rotating card -->
	<!-- OPTION 2 -->
	<div v-if="isCardVal" class="card" @click="touchEnd()" @touchstart.passive="touchStart()" @touchmove.passive="touchMove()" ref="el">
		<div class="card-body">
			<!-- <div class="card-front" :style="isFlipped ? 'visibility: hidden' : ''"> -->
			<div class="card-front">
				<div
					:class="'certification2 ' + sideVal + (!hasNoBack ? ' certification-back' : '')"
					data-aos="fade-in"
					data-aos-offset="-700"
					data-aos-delay="50"
					data-aos-duration="500"
					data-aos-easing="ease-in-out"
					data-aos-mirror="true"
					data-aos-once="true"
					data-aos-anchor-placement="center-center"
				>
					<div v-if="!hasNoBack" class="flip-info"><font-awesome-icon :icon="['fas', 'info-circle']" class="icon-small-dark" /></div>
					<div :class="['certification-logo', isShiftLogoVal ? 'certification-logo-shift' : '']">
						<slot name="logo"></slot>
					</div>
					<div class="certification-description">
						<span style="font-weight: bold"><slot name="title"></slot></span><br />
						<span><slot name="date"></slot></span>
					</div>
				</div>
				<!-- <div :class="'certification ' + sideVal" data-aos="fade-in" data-aos-offset="-700" data-aos-delay="50" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true" data-aos-anchor-placement="center-center">
					<div class="flip-info"><font-awesome-icon :icon="['fas', 'info-circle']" class="icon-small-dark" /></div>
					!-- Earmark works, but is not as intuitive.... users might think it is just a design gimmick. Also shadow is not 100% ideal ->
					!-- <div class="earmark"></div>
	<div class="earmark2"></div> ->
					<div :class="['certification-logo', isShiftLogoVal ? 'certification-logo-shift' : '']">
						<slot name="logo"></slot>
					</div>
					<div class="certification-description">
						<span style="font-weight: bold"><slot name="title"></slot></span><br />
						<span><slot name="details"></slot></span>
						<span><slot name="date"></slot></span>
					</div>
				</div> -->

				<!-- </div>
										<div class="flip-card-back">
											<h1>John Doe</h1>
											<p>Architect & Engineer</p>
											<p>We love that guy</p>
											</div>
										</div>
									</div> -->
			</div>
			<!-- OPTION 2 -->
			<div class="card-back" v-if="!hasNoBack">
				<!-- <div class="certification"> -->
				<div :class="'certification2 ' + sideVal" data-aos="fade-in" data-aos-offset="-700" data-aos-delay="50" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true" data-aos-anchor-placement="center-center">
					<div v-if="!hasNoBack" class="flip-info"><font-awesome-icon :icon="['fas', 'chevron-left']" class="icon-small-dark" /></div>

					<!-- <div class="flip-info"><font-awesome-icon :icon="['fas', 'info-circle']" class="icon-small-dark" /></div> -->
					<!-- Earmark works, but is not as intuitive.... users might think it is just a design gimmick. Also shadow is not 100% ideal -> !--
					<div class="earmark"></div>
					<div class="earmark2"></div>
					-->
					<!-- <div :class="['certification-logo', isShiftLogoVal ? 'certification-logo-shift' : '']">
						<slot name="logo"></slot>
					</div> -->
					<div class="certification-description">
						<span style="font-weight: bold"><slot name="title"></slot></span><br />
						<span><slot name="details"></slot></span>
						<!-- <span><slot name="date"></slot></span> -->
					</div>
				</div>
				<!-- </div> -->

				<!-- <div :class="'certification ' + sideVal" data-aos="fade-in" data-aos-offset="-700" data-aos-delay="50" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true" data-aos-anchor-placement="center-center"> -->
				<!-- <div :class="['certification-logo', isShiftLogoVal ? 'certification-logo-shift' : '']">
					<slot name="logo"></slot>
				</div>
				<div class="certification-description">
				</div>
			</div> -->
			</div>
		</div>
	</div>
	<!-- </div> -->
	<!-- OPTION 1 -->
	<!-- </div>
			<div class="back">
				<div :class="'certification ' + sideVal" data-aos="fade-in" data-aos-offset="-700" data-aos-delay="50" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true" data-aos-anchor-placement="center-center">
					<div :class="['certification-logo', isShiftLogoVal ? 'certification-logo-shift' : '']">
						<slot name="logo"></slot>
					</div>
					<div class="certification-description">
					</div>
				</div>
			</div> -->
	<!-- </div>
	</div> -->

	<!-- Collapsing Card -->
	<span v-if="!isCardVal" :id="'certification-' + pIdVal" style="display: block; position: relative; top: -4em; visibility: hidden;"></span>
	<div
		v-if="!isCardVal"
		:class="'certification certification-left project' + (hasNoBack ? ' project-single' : '')"
		style="min-height: initial !important;"
		data-aos="fade-in"
		data-aos-offset="-500"
		data-aos-delay="50"
		data-aos-duration="500"
		data-aos-easing="ease-in-out"
		data-aos-mirror="true"
		data-aos-once="true"
		data-aos-anchor-placement="top-center"
		@click="toggle(pIdVal)"
		ref="el"
	>
		<div class="accordion">
			<div v-if="!hasNoBack" class="flip-info-project flip-info-project-expand"><font-awesome-icon :icon="['fas', 'info-circle']" class="icon-small-dark" /></div>
			<div v-if="!hasNoBack" class="flip-info-project flip-info-project-collapse"><font-awesome-icon :icon="['fas', 'chevron-left']" class="icon-small-dark" /></div>

			<div style="display: flex; margin-top: 1em;">
				<div :class="['certification-logo', isShiftLogoVal ? 'certification-logo-shift' : '']">
					<slot name="logo"></slot>
				</div>
				<div class="certification-description" style="font-weight: bold; margin-top: 1em;"><slot name="title"></slot></div>
			</div>
			<div class="certification-description">
				<span><slot name="date"></slot></span>
			</div>
		</div>

		<div class="project-content-main">
			<div class="project-content-description">
				<slot name="details"></slot>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref } from 'vue';

	let isScrolling = false;
	// let isFlipped = ref(false);
	let isToggled = ref(false);

	export default {
		name: 'CertificationBlock',
		props: {
			side: String,
			isShiftLogo: Boolean,
			hasNoBack: Boolean,
			isCard: Boolean,
			pId: Number,
		},
		methods: {
			//
			touchStart() {
				isScrolling = false;
			},
			touchEnd() {
				if (!isScrolling && !this.hasNoBack) {
					// Target is always the current target - if user clicks on element within, that element will get the class assigned, not its parent
					// event.target.className += 'hover';
					// event.target.classList.toggle('hover');
					// Therefore, we use the ref pattern to identify the parent https://stackoverflow.com/questions/44568477/get-reference-to-element-in-method-in-vue-js
					// this.isFlipped = !this.isFlipped;
					this.$refs.el.classList.toggle('hover');
				}
			},
			touchMove() {
				isScrolling = true;
			},
			// For non-cards
			toggle(id) {
				if (this.hasNoBack) return;

				let el = this.$refs.el.firstElementChild;

				var panel = el.nextElementSibling;
				if (panel.style.maxHeight) {
					panel.style.maxHeight = null;
				} else {
					panel.style.maxHeight = panel.scrollHeight + 'px';
				}

				let collapse = el.children[0];
				let expand = el.children[1];

				if (panel.style.maxHeight) {
					collapse.style.display = 'none';
					expand.style.display = 'inline';
				} else {
					collapse.style.display = 'inline';
					expand.style.display = 'none';
				}
				document.getElementById('certification-' + id).scrollIntoView();
			},
		},
		data() {
			return {
				sideVal: this.side !== undefined ? 'certification-' + this.side : 'certification',
				isShiftLogoVal: this.isShiftLogo,
				hasNoBackVal: this.hasNoBack,
				isCardVal: this.isCard !== undefined ? this.isCard : true,
				pIdVal: this.pId,
				// isFlipped,
				isToggled,
			};
		},
		mounted() {},
		setup() {},
	};
</script>
