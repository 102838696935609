<template>
	<!-- z-index keeps circles on top of line when using animations -->
	<div :class="['container', sideVal]" :data-aos="'slide-' + this.reverseSide2" data-aos-offset="-800" data-aos-delay="50" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true" data-aos-anchor-placement="center-center" style="z-index: 5">
		<div class="timeline-content">
			<h2>
				<slot name="position"></slot>
			</h2>
			<span class="timeline-location"><slot name="employer"></slot>,<slot name="location"></slot></span>
			<div class="timeline-header">
				<div :class="['timeline-logo', sideVal + '-logo']">
					<slot name="image"></slot>
				</div>
				<div :class="['timeline-description', sideVal + '-description']">
					<p>
						<slot name="description"></slot>
					</p>
				</div>
			</div>
			<div :class="['timeline-date', sideVal + '-date']">
				<!-- <h2 :class="['timeline-date-title', sideVal + '-date-title']">
			<span class="timeline-date-part timeline-date-thin"><slot name="startM"></slot></span>
			<span class="timeline-date-part"><slot name="startY"></slot></span>
			<span class="timeline-date-thin">—<br /></span>
			<span class="timeline-date-part timeline-date-thin"><slot name="endM"></slot></span>
			<span class="timeline-date-part" style="margin-right: 0.7em;"><slot name="endY"></slot></span>
		</h2> -->

				<!-- <div style="width: 20em; text-align: left"> -->
				<div class="timeline-date-block">
					<!-- <div style="float: right;  display: inline-block; float: right;text-align: left;"> -->
					<div>
						<h2 :class="['timeline-date-title', sideVal + '-date-title']">
							<span class="timeline-date-part timeline-date-thin"><slot name="startM"></slot></span>
							<span class="timeline-date-part"><slot name="startY"></slot></span>
							<span class="timeline-date-thin">—</span><br />
							<span class="timeline-date-part timeline-date-thin"><slot name="endM"></slot></span>
							<span class="timeline-date-part"><slot name="endY"></slot></span>&nbsp;
						</h2>
					</div>
				</div>
				<!-- Details on other side -->
				<!-- <slot name="details"></slot> -->
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'TimelineBlock',
		props: {
			side: String,
		},
		data() {
			return {
				sideVal: this.side == 'left' ? 'timeline-left' : 'timeline-right',
				reverseSide: this.side == 'left' ? 'timeline-right' : 'timeline-left',
				reverseSide2: this.side == 'left' ? 'right' : 'left',
			};
		},
		mounted() {},
		setup() {},
	};
</script>
