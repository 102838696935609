<!--
TODO: Add to Google index: https://search.google.com/search-console?hl=de
TODO: Update picture on Trailhead
TODO: (prio 3) Multi-language https://kazupon.github.io/vue-i18n/started.html#html (has hot reload) - huge effort to translate into German, don't do!

INDEX.HTML
Re-enable for production build. Will block socket-js which will block hot reload during development... -> Done via grunt
  <meta http-equiv="Content-Security-Policy"
    content="default-src 'self' data: https: ws: http: 'unsafe-inline' 'unsafe-eval'; script-src 'self' 'unsafe-inline'; font-src fonts.gstatic.com fonts.googleapis.com 'self' 'unsafe-inline'; style-src fonts.googleapis.com 'self' 'unsafe-inline';">
  <meta http-equiv="X-Content-Security-Policy" content="default-src 'self' data: https: ws: http: 'unsafe-inline' 'unsafe-eval'; script-src 'self' 'unsafe-inline'; font-src fonts.gstatic.com fonts.googleapis.com
    'self' 'unsafe-inline'; style-src fonts.googleapis.com 'self' 'unsafe-inline';">
  <meta http-equiv="X-WebKit-CSP" content="default-src 'self' data: https: ws: http: 'unsafe-inline' 'unsafe-eval'; script-src 'self' 'unsafe-inline'; font-src fonts.gstatic.com fonts.googleapis.com
    'self' 'unsafe-inline'; style-src fonts.googleapis.com 'self' 'unsafe-inline';">
-->

<!-- HOWTO
############### Run
npm run serve (for development mode)

############### Dev
- CSP Meta tags: Disable CSP meta tags in index.html, otherwise socket-js will be blocked, this will prevent Vue.js from hot reloading upon changes
- CSP Meta tags: Add "http"


############### Build
grunt (cd /grunt/ first) // Vendorizes CSS, compresses images; removes "http" from meta tags to only allow https etc.; use grunt dev for dev
npm run build // Minimizes automatially; use npm run serve for dev; NOTE: npm run build is not required for vercel --prod
vercel --prod // use vercel for dev

############### Font Awesome Icons
- Font Awesome offers different 'packs'
- Overview: https://fontawesome.com/icons/ (open icon to see its pack)
- You need to retrieve every single icon you want to use (to save performance, not all are loaded by defaut)
- See see https://dev.to/karlodelarosa3/font-awesome-5-as-a-component-in-vue-js-7h ; https://codepen.io/nekyox/full/mdJrNVxfor syntax
- Icons in core pack:
<font-awesome-icon :icon="envelope']" />
<font-awesome-icon :icon="['fas', 'envelope']" />
- Icons in brand pack require the prefix ("Font Awesome Brand"):
<font-awesome-icon :icon="['fab', 'linkedin']" />
- You MUST use :icon (short for v-icon), not just icon
- Names are without the "fa" prefix, e.g. just "linkedin" not "faLinkedin" (so different then using FA without Vue where you use "<i class="fa faEnvelope>")
- To get the exact name to import, open the ".js" file in D:\Git\sharingDesigner\vue3_NEW\CurriculumVitae\node_modules\@fortawesome\ e.g. faLinkedinIn.js
// NOTE: If icon is not available in Vue module, download from website: https://fontawesome.com/icons/construction?style=solid - inspect SVG icon in the middle (differents izes), copy <svg>, save as .svg file - that's it!

############### Webkoll
- Check cookie usage: https://webbkoll.dataskydd.net/en/results?url=http%3A%2F%2Fcurriculum-vitae.mschmidtkorth.vercel.app
- "sockjs.js:1606 Refused to connect to 'http://192.168.1.240:8080/sockjs-node/info?t=1603547419126' because it violates the following Content Security Policy directive: "default-src 'self'". Note that 'connect-src' was not explicitly set, so 'default-src' is used as a fallback." error is OK - only during development, not when deployed to Vercel or others (due to CSP meta tags in index.html)
-->

<template>
	<Modal v-if="isShowModal" @close="isShowModal = false" :showLanguages="true">
		<template v-slot:header></template>

		<template v-slot:body></template>
	</Modal>
	<div id="wrapper-app" @keydown.esc="isShowModal = false" tabindex="0">
		<div id="wrapper-column">
			<div id="column-left" class="columns">
				<header class="main-header">
					<div id="me-image"></div>
					<div class="header-content">
						<div class="contact-button-wrapper">
							<h1 id="name">Michael Schmidt-Korth</h1>
							<span class="me">Salesforce <span class="attributes"></span></span>
							<ul id="contact-buttons" style="margin-bottom: 7em">
								<li>
									<!-- Email obfuscation via plain JS (spam protection) -->
									<a href="javascript:void(0);" class="nobox tooltip" data-name="michael" data-domain="schmidt-korth" data-tld="de" onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;">
										<font-awesome-icon :icon="['fas', 'envelope']" class="contact-button-icon" />
										<span class="tooltiptext tooltip-small">Contact me via email</span>
									</a>
								</li>
								<li>
									<a class="nobox tooltip" href="https://linkedin.com/in/mschmidtkorth" target="_blank">
										<font-awesome-icon :icon="['fab', 'linkedin-in']" class="contact-button-icon" />
										<span class="tooltiptext tooltip-wide">Connect with me on LinkedIn</span>
									</a>
								</li>
								<li>
									<a class="nobox tooltip" href="https://www.xing.com/profile/Michael_SchmidtKorth=" target="_blank">
										<font-awesome-icon :icon="['fab', 'xing']" class="contact-button-icon" />
										<span class="tooltiptext tooltip-wide">Connect with me on XING</span>
									</a>
								</li>
								<li>
									<a class="nobox tooltip" href="https://trailblazer.me/id?uid=michael-schmidt-korth&cmty=trailhead" target="_blank">
										<img src="../assets/salesforce.svg" style="width: 2.5em; vertical-align: middle" alt="Company Logo Salesforce" />
										<span class="tooltiptext tooltip-wide">Follow me on Trailhead</span>
									</a>
								</li>
								<li>
									<a class="nobox tooltip" href="https://github.com/mschmidtkorth" target="_blank">
										<font-awesome-icon :icon="['fab', 'github']" class="contact-button-icon" />
										<span class="tooltiptext tooltip-small">Follow me on Github</span>
									</a>
								</li>
							</ul>
							<!-- <div class="mobile-start" style="margin-top: 2.5em">
								<a href="#who-mobile" onclick="document.getElementById('who').scrollIntoView()">
									<font-awesome-icon :icon="['fas', 'chevron-circle-down']" class="contact-button-icon" />
								</a>
							</div> -->
						</div>
					</div>
					<div class="mobile-start" style="display: flex; align-items: center; justify-content: center; position: absolute; bottom: 6%; z-index: 100; width: 100%">
						<a href="#who-mobile" onclick="document.getElementById('who').scrollIntoView()" style="position: absolute">
							<font-awesome-icon :icon="['fas', 'chevron-circle-down']" class="contact-button-icon" />
						</a>
					</div>
				</header>
			</div>

			<div id="column-right" class="columns">
				<div id="menu">
					<ul :class="['menu', isShowMenu ? 'menu-show' : 'menu-hide']">
						<li>
							<a href="#who" onclick="document.getElementById('who').scrollIntoView()">
								<span class="who"><font-awesome-icon :icon="['fas', 'home']" /></span>
							</a>
						</li>
						<li>
							<a href="#work" :class="isScrollWork ? 'highlight' : ''" onclick="document.getElementById('work').scrollIntoView()">Work Experience</a>
						</li>
						<li>
							<a href="#projects" :class="isScrollProjects ? 'highlight' : ''" onclick="document.getElementById('projects').scrollIntoView()">Projects</a>
						</li>
						<li>
							<a href="#skills" :class="isScrollSkills ? 'highlight' : ''" onclick="document.getElementById('skills').scrollIntoView()">Skills</a>
						</li>
						<li>
							<a href="#certificationsM" :class="isScrollCertifications ? 'highlight' : ''" onclick="document.getElementById('certificationsM').scrollIntoView()">Certifications</a>
						</li>
						<li>
							<a href="#testimonials" :class="isScrollTestimonials ? 'highlight' : ''" onclick="document.getElementById('testimonials').scrollIntoView()">Testimonials</a>
						</li>
						<li>
							<a href="#contact" :class="isScrollContact ? 'highlight' : ''" onclick="document.getElementById('contact').scrollIntoView()">Contact</a>
						</li>
						<li id="menu-button" class="menu-button" onclick="document.getElementById('menu-button').scrollIntoView()">
							<a href="javascript:void(0);" class="icon" @click="toggleMenu()">
								<font-awesome-icon :icon="['fas', 'bars']" />
							</a>
						</li>
					</ul>
				</div>

				<section class="wrapper-section">
					<div>
						<div>
							<span id="who"></span>
							<div class="section-row">
								<div class="section-headline">
									<h2>Who I am</h2>
									<!-- Full-width title -->
									<!-- <div class="section-headline" style="background-color: #ffb100a8; padding-left: 1em; padding-right: 1em; padding-left: 1em;"><h2>Project Work</h2></div> -->
								</div>
								<!-- Headline style with bottom thick border -->
								<!-- Inline block to restrict border to text width
								<div class="section-headline" style="position: relative;display: inline-block;padding: 0 5px;">
									<span style="position: absolute;height: 9px;width: 100%;bottom: 15px;opacity: 0.4;left: 0; background-color: #ffb100"></span>
									Relative to display on top of border
									<h2 style="position: relative;">Who I am</h2>
								</div> -->
							</div>
							<div class="section-row justify">
								<span id="who-mobile"></span>
								<p>I am a highly experienced, trusted and driven AI Transformation Director at <a href="https://www.salesforce.com" target="_blank">Salesforce.com</a>. I plan, design and implement Salesforce solutions for large enterprises across industries. I am recognized as a trusted expert for Salesforce products and enterprise software architecture.</p>
								<!-- "with interest in understanding the business objectives and delivering a long-term solution."  TODO: Linkedin, klingt nicht gut - "natürlich sollte man auch daran interesse haben" -->
								<p>Guiding companies through their digital transformation and setting the groundwork for their business strategy is what excites me. Focusing on Artificial Intelligence, I advise customers from vision to execution on AI. I lead architects and developers and guide them implementing solutions with long-term success at enterprise-scale.</p>
								<p>
									I have experience as an IT Architect, Developer, Administrator and Project Manager and a strong background in SAP
									<TooltipAbbreviation :short="'ERP'" :long="'Enterprise Resource Planning'" />. Typically, I work as the Lead Architect for very large, global and multi-/all-cloud Salesforce projects ranging from months to years.
								</p>
								<!-- % I lead our Infrastructure Customer Readiness team at Salesforce where we ensure our support organization is aware and prepared for upcoming infrastructure changes. I am keen on anticipating customer reactions to such maintenances and asking the right questions to our technical teams to provide comprehensive information to our support organization while establishing a customer-focused lens during technical team discussions.

								% I am interested in broadening my business acumen beyond program management. Over the past 7+ years, I have learned the importance of providing excellent visibility on progress, issues, and risks through meetings, emails, Chatter discussions, reports and dashboards in order to tell a story that will resonate with various levels and functions. My welcoming personality provides ease in collaborating with cross-functional teams to deliver business objectives. My eagerness to grow my career and my drive to get in there and help wherever I can, will continue to make me successful in any role I take.

								% I thrive in an environment where I am challenged. The most exciting work for me is discovering areas that need improvement and running with it: develop a strategy; socialize that strategy to gain feedback and buy-in; tweak it from those various conversations; implement it; continue to modify it once we've learned what's working and what is not; and then passing it off to someone who can own it long-term.

								% Specialties: Program management, portfolio management, customer escalations, customer readiness, project management, change management, dependency management, risk management -->
								<!-- TODO: (prio 2) Add resume file -->
								<!-- <button id="download" @click="downloadCV()"><font-awesome-icon :icon="['fas', 'download']" />Download Resume</button> -->
							</div>
						</div>
					</div>
				</section>

				<!-- Fix for AOS changing page width due to cards starting outside of page (especially annoying on mobile): https://github.com/michalsnik/aos/issues/416 -->
				<section class="wrapper-section section-odd" style="overflow-x: hidden">
					<div>
						<div>
							<!-- Offset anchor -->
							<span id="work"></span>
							<div class="section-row">
								<div class="section-headline">
									<h2>Where I have worked</h2>
									<!-- WorkExperience -->
								</div>
							</div>
						</div>
						<div>
							<div class="section-row">
								<div class="timeline">
									<TimelineBlock side="right">

										<template v-slot:position> Director AI Transformation Advisory </template>

										<template v-slot:employer>Salesforce.com</template>

										<template v-slot:location> Zurich (CH) </template>

										<template v-slot:image>
											<img src="../assets/work_salesforce.svg" alt="Company Logo Salesforce" />
										</template>

										<template v-slot:startM> Feb </template>

										<template v-slot:startY> 2024 </template>

										<template v-slot:endM> </template>

										<template v-slot:endY>
											<span style="margin-left: -1em">Today</span>
										</template>

										<template v-slot:description> Advising our customers on delivering long-term value with Artificial Intelligence. </template>

										<template v-slot:details> </template>
									</TimelineBlock>
									<TimelineBlock side="left">

										<template v-slot:position> Director Technical Consulting </template>

										<template v-slot:employer>Salesforce.com</template>

										<template v-slot:location> Zurich (CH) </template>

										<template v-slot:image>
											<img src="../assets/work_salesforce.svg" alt="Company Logo Salesforce" />
										</template>

										<template v-slot:startM> Feb </template>

										<template v-slot:startY> 2022 </template>

										<template v-slot:endM> Jan </template>

										<template v-slot:endY>2024 </template>

										<template v-slot:description> Leading high-impact teams for our most important strategic engagements in Central Europe. </template>

										<template v-slot:details> </template>
									</TimelineBlock>
									<TimelineBlock side="right">

										<template v-slot:position> Senior Technical Architect </template>

										<template v-slot:employer>Salesforce.com</template>

										<template v-slot:location> Zurich (CH) </template>

										<template v-slot:image>
											<img src="../assets/work_salesforce.svg" alt="Company Logo Salesforce" />
										</template>

										<template v-slot:startM> Aug </template>

										<template v-slot:startY> 2018 </template>

										<template v-slot:endM>Jan</template>

										<template v-slot:endY> 2022 </template>

										<template v-slot:description>
											Working with our largest and most engaging customers to bring their Salesforce implementation to life. Driving complex strategic projects across the whole product portfolio Salesforce has to offer. I support our customers on-site during long-term projects.
											<!-- and ensure a successful Salesforce implementation. -->
										</template>

										<template v-slot:details> </template>
									</TimelineBlock>
									<TimelineBlock side="left">

										<template v-slot:position> Technical Architect </template>

										<template v-slot:employer>Salesforce.com</template>

										<template v-slot:location> Munich (DE) </template>

										<template v-slot:image>
											<img src="../assets/work_salesforce.svg" alt="Company Logo Salesforce" />
										</template>

										<template v-slot:startM> Jul </template>

										<template v-slot:startY> 2016 </template>

										<template v-slot:endM> Jul </template>

										<template v-slot:endY> 2018 </template>

										<template v-slot:description> Leading the Technical Architecture during project engagements. Responsibility for leading development teams and managing high-value implementations. </template>

										<template v-slot:details> </template>
									</TimelineBlock>
									<TimelineBlock side="right">

										<template v-slot:position> Salesforce Lead Architect, Lead Developer & Project Lead </template>

										<template v-slot:employer>Schaeffler AG</template>

										<template v-slot:location> Herzogenaurach (DE) </template>

										<template v-slot:image>
											<img src="../assets/work_schaeffler.svg" alt="Company Logo Schaeffler" />
										</template>

										<template v-slot:startM> May </template>

										<template v-slot:startY> 2013 </template>

										<template v-slot:endM> Jun </template>

										<template v-slot:endY> 2016 </template>

										<template v-slot:description> Driving a global multi-org Salesforce implementation as Architect, Project Lead, Developer, Business Analyst and Administrator. Top performer. </template>

										<template v-slot:details> </template>
									</TimelineBlock>
									<TimelineBlock side="left">

										<template v-slot:position> Integrated degree student </template>

										<template v-slot:employer>Schaeffler AG</template>

										<template v-slot:location> Herzogenaurach (DE) </template>

										<template v-slot:image>
											<img src="../assets/work_schaeffler.svg" alt="Company Logo Schaeffler" />
										</template>

										<template v-slot:startM> Oct </template>

										<template v-slot:startY> 2010 </template>

										<template v-slot:endM> Sep </template>

										<template v-slot:endY> 2013 </template>

										<template v-slot:description> Internships in various departments: IT Sales/
											<TooltipAbbreviation :short="'CRM'" :long="'Customer Relationship Management'" />, IT SAP
											<TooltipAbbreviation :short="'ERP'" :long="'Enterprise Resource Planning'" /> Production, IT SAP Information Controlling, IT SAP Logistics, IT SAP Basis
										</template>

										<template v-slot:details> </template>
									</TimelineBlock>
									<div class="section-headline headline-additional">
										<h2>Education</h2>
									</div>
									<TimelineBlock side="right">

										<template v-slot:position> B.Sc. Business Information Systems </template>

										<template v-slot:employer>Cooperative State University</template>

										<template v-slot:location> Mannheim (DE) </template>

										<template v-slot:image>
											<img src="../assets/work_dhbw.svg" alt="Company Logo Cooperative State University Mannheim" />
										</template>

										<template v-slot:startM> Oct </template>

										<template v-slot:startY> 2010 </template>

										<template v-slot:endM> Sep </template>

										<template v-slot:endY> 2013 </template>

										<template v-slot:description>
											Best thesis in class of 2013.<br />
											Best degree in class of 2013 at Schaeffler.
										</template>

										<template v-slot:details> </template>
									</TimelineBlock>
									<TimelineBlock side="left">

										<template v-slot:position> A-levels </template>

										<template v-slot:employer>Science-/technology-oriented Grammar School</template>

										<template v-slot:location> Scheinfeld (DE) </template>

										<template v-slot:image>
											<img src="../assets/school_scheinfeld.svg" height="48" alt="Company Logo Grammar School Scheinfeld" />
										</template>

										<template v-slot:startM> Sep </template>

										<template v-slot:startY> 2000 </template>

										<template v-slot:endM> Jun </template>

										<template v-slot:endY> 2010 </template>

										<template v-slot:description> Best thesis in class of 2010. </template>

										<template v-slot:details> </template>
									</TimelineBlock>
									<!-- Alternate timeline blocks -->
									<!-- <div class="container left">
									<div class="timeline-content" style="padding-left: 0;padding-right: 0; padding-top: 0;">
									<h1 style="display: block; padding: 0; background-color: #ffb100a8; padding-left: 1em">2017</h1>
									<div style="padding-left: 1em;">
									<p>Lorem ipsum..</p>
									</div>
									<div style="float: right;position: absolute;left: 120%;top: 0em; width: 100%;">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. </div>
									</div>
								</div> -->
									<!-- <div class="container right">
									<div class="timeline-content" style="padding-left: 0;padding-right: 0; padding-top: 0; padding-bottom: 0;">
									<h1 style="display: block; padding: 0; background-color: #ffb100a8; padding-left: 1em">2017</h1>
									<div style="float: right;position: absolute;right: 120%;top: 0em; width: 100%;">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.</div>
									</div>
								</div> -->
								</div>
							</div>
						</div>
					</div>
				</section>

				<section class="wrapper-section">
					<div>
						<span id="projects"></span>
						<div class="section-row">
							<div class="section-headline">
								<h2>Projects I Have Lead</h2>
								<!-- Project Work/Experience -->
							</div>
						</div>
					</div>
					<!-- Trainings: Subscription based business models 1d Soft Skills 2D Certifed Scrum Master Training 2D; Presentaton Skills (Powrspeaking) 10/2017
Soft Skills 6/17
(Beyond the Cloud 5/17) -->
					<!-- job interviews for new hires -->
					<!-- See OneNote -->
					<!-- Rollout into X countries (Schaeffler?) -->
					<!-- MEHR MIT SOLCHEN KURZEN BUZZWORDS ARBEITEN -->
					<!-- Business Process Design & Re-Engineering -->
					<!-- IT Architecture -->
					<!-- IT Strategy -->
					<!-- SOA Service-oriented architecture -->
					<!-- Microservice and serverless -->
					<!-- Enterprise Application Integration;  -->
					<!-- "Unless you have 20+ years' experience, make it 1 page. You can do it." -->
					<!-- Try to read it in 10 seconds or less and see what you take away from it. That's about the initial screen time before someone makes an initial up/down decision, so you'll want to examine it from that perspective. -->
					<!-- s. auch email (?) "TA Responsibilities"
									-->
					<!-- General qualities: efficient and effective approach
											team player, always have a helping hand
											strong committment -->

					<!-- CSS Grid or Flexbox does not work, as it will make all items on the same row have the same height - so, if you expand one, the other one on the same row will be expaneded as well. I can use JS to prevent it from fully expanding, but then it will just have whitespace below. Instead I want items from below to fill this white space -->
					<!-- <div style="display: grid; grid-auto-rows: 0fr; grid-gap: 3rem; grid-template-columns: repeat(2, 48%); width: 100%">
						<div style="width: 100%; border:1px solid #000">
							1
							<div>
								content<br />content<br />content<br />content<br />content<br />content<br />content<br />content<br />content<br />content<br />content<br />content<br />content<br />content<br />content<br />content<br />content<br />content<br />content<br />content<br />content<br />content<br />
							</div>
						</div>
						<div style="width: 100%; border:1px solid #000">
							2
							<div>content<br />content<br />content<br />content</div>
						</div>
						<div style="width: 100%; border:1px solid #000">3</div>
						<div style="width: 100%; border:1px solid #000">4</div>
					</div> -->
					<!--<div style="display: grid; grid-auto-rows: 0fr; grid-gap: 3rem; grid-template-columns: repeat(2, 48%); width: 100%">-->
					<div class="section-content">
						<div class="section-row">
							<div class="section-item">
								<p>I have been working as an external consultant for <a href="https://www.salesforce.com" target="_blank">Salesforce.com</a> in various industries and as an internal consultant for <a href="https://www.schaeffler.com" target="_blank">Schaeffler</a>.</p>
								<div id="project-wrapper" class="certification-wrapper">
									<div class="projects-left">
										<!-- NOTE: Reminder for the future - I have used a different approach here (and for Skills) than for Certifications. Certs are easy - just a flexbox container via CSS, responsiveness is handled automatically. This would generally also work here, BUT: For Projects/Skills I have a toggle to expand/collapse the text. In a flex box environment this would mean, if I have two columns, expand one on the left, the one on the right it would be of the same height - but not expanded. Items from below would not move up to fill the space, ie. there would be unused whitespace. Therefore I use a different CSS aapproach, where I will have two separate <div> columns ("projects-left"/right), i.e. no responsiveness per CSS. To get it back (ie merge both columns into one) for smaller devices, I use JS/Vue to display the right components per column (in phone mode, the second column is just empty). Actually, I don#t really move, I conditionally display. Moving (via JS) I tried as well - via appendChild() etc. - but that doesn't work with Vue (destroys reactivity). It also has the advantage that I have fine grained control over which item to display in which column when. -->
										<!-- Dynamic component; we identify the actual component via "is". Instead of using <Projects />, we dynamically select the right component to display. In this example, I use it to dynamically select the subcomponents actually by passing the Ids of those to be displayed. -->
										<!-- "projectsCmp" is the identifier for the component; it has to be returned in data -->
										<!-- Key must be unique. Is only used by Vue to identify the data, to be able to rerender it if needed. The key is very important: The key attribute helps Vue to know that a specific component is tied to a specific piece of data. If the key stays the same, it won't change the component, but if the key changes, Vue knows that it should get rid of the old component and create a new one. If we used "i" instead of "projectBlock" here, it would still work, but "old" components would remain upon rerendering -->
										<component v-for="(projectBlock, i) in projectBlocks1" :key="'l_' + projectBlock" :is="projectsCmp" :id="projectBlock" />
									</div>
									<div class="projects-right">
										<component v-for="(projectBlock, i) in projectBlocks2" :key="'r_' + projectBlock" :is="projectsCmp" :id="projectBlock" />
									</div>
								</div>
								<div class="section-item">
									<p>I have also lead, supported and developed various initiatives:</p>
									<div class="certification2-wrapper">
										<!-- <div class="certification-wrapper certification-white"> -->
										<!-- OK -->
										<CertificationBlock :isCard="true" :isShiftLogo="true" side="right">

											<template v-slot:logo>
												<font-awesome-icon :icon="['fas', 'hand-holding-medical']" class="icon-small-bright" />
											</template>

											<template v-slot:title> Pro bono work </template>

											<template v-slot:details> Supported a non-profit organisation for entrepreneurship to implement Salesforce for constituency, fundraising and campaign management. </template>

											<template v-slot:date> 2020—Today </template>
										</CertificationBlock>
										<!-- OK -->
										<CertificationBlock :isCard="true" :isShiftLogo="true" side="left">

											<template v-slot:logo>
												<font-awesome-icon :icon="['fas', 'hands-helping']" class="icon-small-bright" />
											</template>

											<template v-slot:title> Mentoring </template>

											<template v-slot:details> Supporting new hires and colleagues in need of mentoring. </template>

											<template v-slot:date> 2017—Today </template>
										</CertificationBlock>
										<CertificationBlock :isCard="true" :isShiftLogo="true" side="left">

											<template v-slot:logo>
												<font-awesome-icon :icon="['fas', 'clipboard-check']" class="icon-small-bright" />
											</template>

											<template v-slot:title> Learning from Failures Lead (EMEA Central) </template>

											<template v-slot:details> Defined and established a framework for after action reviews and outcome-focused learnings. </template>

											<template v-slot:date> 2022—2023 </template>
										</CertificationBlock>
										<!-- OK -->
										<CertificationBlock :isCard="true" :isShiftLogo="true" side="right">

											<template v-slot:logo>
												<!-- <font-awesome-icon :icon="['fas', 'sitemap']" class="icon-small-bright" /> -->
												<font-awesome-icon :icon="['fas', 'tasks']" class="icon-small-bright" />
											</template>

											<template v-slot:title> Salesforce Permission Manager </template>

											<template v-slot:details> Developed an internal comprehensive tool to manage all permissions in Salesforce and automatically document users, roles and permissions. Used globally for many medium and large implementations. </template>

											<template v-slot:date>
												<!-- is within <span> so can't use <p> -->
												2019—Today
											</template>
										</CertificationBlock>
										<!-- OK -->
										<CertificationBlock :isCard="true" :isShiftLogo="true" side="left">

											<template v-slot:logo>
												<font-awesome-icon :icon="['fas', 'project-diagram']" class="icon-small-bright" />
											</template>

											<template v-slot:title> Salesforce Sharing Designer </template>

											<template v-slot:details> Developed an interview-based public resource to provide recommendations and solution proposals for complex requirements in the area of Salesforce permissions and access. </template>

											<template v-slot:date> 2020—Today </template>
										</CertificationBlock>
										<CertificationBlock :isCard="true" :isShiftLogo="true">

											<template v-slot:logo>
												<font-awesome-icon :icon="['fas', 'cog']" class="icon-small-bright" />
											</template>

											<template v-slot:title> Salesforce Automation Guide </template>

											<template v-slot:details> Developed a <a href="https://salesforce-automation.herokuapp.com/" target="_blank">public</a> resource to provide best practices, recommendations and knowledge around all Salesforce automation tools. </template>

											<template v-slot:date> 2020 </template>
										</CertificationBlock>
										<!-- OK -->
										<CertificationBlock :isCard="true" :isShiftLogo="true" side="left">

											<template v-slot:logo>
												<font-awesome-icon :icon="['fas', 'people-carry']" class="icon-small-bright" />
											</template>

											<template v-slot:title> Employee Engagement Lead (Germany, Austria, Switzerland) </template>

											<template v-slot:details> Leading an initiative to improve employee engagement, foster trust and productivity and align with executive sponsors.</template>

											<template v-slot:date> 2018—2019 </template>
										</CertificationBlock>
										<CertificationBlock :isCard="true" :isShiftLogo="true" side="left">

											<template v-slot:logo>
												<font-awesome-icon :icon="['fas', 'users']" class="icon-small-bright" />
											</template>

											<template v-slot:title> Other Initiatives </template>

											<template v-slot:details> Lead and participated in various initiatives every FY: Dreamforce, Delivery Excellence, Salesforce DX, internal enablement (bootcamp &amp; internal trainings).</template>

											<template v-slot:date> 2018—2019 </template>
										</CertificationBlock>
										<CertificationBlock :isCard="true" :isShiftLogo="true" side="right">

											<template v-slot:logo>
												<font-awesome-icon :icon="['fas', 'file']" class="icon-small-bright" />
											</template>

											<template v-slot:title> Global Operating Model </template>

											<template v-slot:details> Supported an exhaustive internal best practice document about implementing a global operating model for enterprises working with Salesforce. </template>

											<template v-slot:date> 2018 </template>
										</CertificationBlock>
										<!-- OK -->
										<CertificationBlock :isCard="true" :isShiftLogo="true">

											<template v-slot:logo>
												<font-awesome-icon :icon="['fas', 'cog']" class="icon-small-bright" />
											</template>

											<template v-slot:title> Salesforce Sandbox Management Guide </template>

											<template v-slot:details> Provided a heavily used internal resource around org strategies and sandbox management best practices. </template>

											<template v-slot:date> 2017 </template>
										</CertificationBlock>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section class="wrapper-section section-odd">
					<div>
						<div>
							<span id="skills"></span>
							<div class="section-row">
								<div class="section-headline">
									<h2>My Skills</h2>
								</div>
							</div>
						</div>
						<div class="section-content">
							<div class="section-row">
								<div class="section-item">
									<!-- <div class="certification2-wrapper"> If using pure cards -->
									<div class="certification-wrapper">
										<div class="projects-left">
											<component v-for="(skillBlock, i) in skillBlocks1" :key="'l_' + skillBlock" :is="skillsCmp" :id="skillBlock" />
										</div>
										<div class="projects-right">
											<component v-for="(skillBlock, i) in skillBlocks2" :key="'r_' + skillBlock" :is="skillsCmp" :id="skillBlock" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section class="wrapper-section">
					<div>
						<div>
							<span id="certificationsM"></span>
							<div class="section-row">
								<div class="section-headline">
									<h2>My Certifications &amp; Trainings</h2>
								</div>
							</div>
						</div>
						<div class="section-content">
							<div class="section-row">
								<div class="section-item">
									<div class="certification2-wrapper">
										<CertificationBlock :isCard="true" :hasNoBack="true">

											<template v-slot:logo>
												<img src="../assets/University_St_Gallen.svg" alt="Company Logo University of Sankt Gallen" width="80" />
											</template>

											<template v-slot:title> Consulting with Leadership (St. Gallen Executive School of Management, Switzerland)</template>

											<template v-slot:date> May 2022—July 2022 </template>
										</CertificationBlock>
										<CertificationBlock :isCard="true" :hasNoBack="true">

											<template v-slot:logo>
												<img src="../assets/work_salesforce.svg" alt="Company Logo Salesforce" />
											</template>

											<template v-slot:title> Talent for Leadership for Salesforce Leaders </template>

											<template v-slot:date> Mar 2022—Mar 2023 </template>
										</CertificationBlock>
										<CertificationBlock :isCard="true" :hasNoBack="true">

											<template v-slot:logo>
												<img src="../assets/mulesoft.svg" alt="Company Logo Mulesoft" width="80" />
											</template>

											<template v-slot:title> Mulesoft Platform Architect </template>

											<template v-slot:date> Feb 2022 </template>
										</CertificationBlock>
										<CertificationBlock :isCard="true" :hasNoBack="true">

											<template v-slot:logo>
												<img src="../assets/MIT_School_of_Management_Small.svg" alt="Logo MIT Sloan School of Management" width="100" />
											</template>

											<template v-slot:title> Managing Technical Professionals and Organizations (MIT Sloan School of Management) </template>

											<template v-slot:date> Dec 2021 </template>
										</CertificationBlock>
										<CertificationBlock :isCard="true" :hasNoBack="true">

											<template v-slot:logo>
												<img src="../assets/work_salesforce.svg" alt="Company Logo Salesforce" />
											</template>

											<template v-slot:title> Accelerate - Management Training </template>

											<template v-slot:date> Dec 2020—Dec 2021 </template>
										</CertificationBlock>
										<CertificationBlock :isCard="true" :hasNoBack="true">

											<template v-slot:logo>
												<img src="../assets/UC_Riverside_logo_Small.svg" alt="Logo University of California, Riverside" width="70" />
											</template>

											<template v-slot:title> Design Thinking (University of California, Riverside) </template>

											<template v-slot:date> Nov 2021 </template>
										</CertificationBlock>
										<CertificationBlock :isCard="true" :hasNoBack="true">

											<template v-slot:logo>
												<img src="../assets/work_salesforce.svg" alt="Company Logo Salesforce" />
											</template>

											<template v-slot:title> Salesforce Sharing and Visibility Architect Administrator </template>

											<template v-slot:date> Feb 2021 (#21852767) </template>
										</CertificationBlock>
										<CertificationBlock :isCard="true" :hasNoBack="true">

											<template v-slot:logo>
												<img src="../assets/company_togaf.svg" width="100" alt="Company Logo TOGAF" />
											</template>

											<template v-slot:title> The Open Group Architecture Framework (TOGAF) 9.2 L1+2 </template>

											<template v-slot:date> May 2018 </template>
										</CertificationBlock>
										<CertificationBlock :isCard="true" side="left" :hasNoBack="true">

											<template v-slot:logo>
												<img src="../assets/company_scrum-alliance.svg" width="100" alt="Company Logo Scrum Alliance" />
											</template>

											<template v-slot:title> Certified Scrum Master (CSM) </template>

											<template v-slot:date> Jul 2017 (#000668430) </template>
										</CertificationBlock>
										<CertificationBlock :isCard="true" :hasNoBack="true">

											<template v-slot:logo>
												<img src="../assets/work_salesforce.svg" alt="Company Logo Salesforce" />
											</template>

											<template v-slot:title> Salesforce Advanced Administrator </template>

											<template v-slot:date> Aug 2016 (#9374937) </template>
										</CertificationBlock>
										<CertificationBlock :isCard="true" side="left" :hasNoBack="true">

											<template v-slot:logo>
												<img src="../assets/work_salesforce.svg" alt="Company Logo Salesforce" />
											</template>

											<template v-slot:title> Salesforce Platform App Builder </template>

											<template v-slot:date> Aug 2016 (#9374958) </template>
										</CertificationBlock>
										<CertificationBlock :isCard="true" :hasNoBack="true">

											<template v-slot:logo>
												<img src="../assets/work_salesforce.svg" alt="Company Logo Salesforce" />
											</template>

											<template v-slot:title> Salesforce Sales Cloud Consultant </template>

											<template v-slot:date> Aug 2016 (#9433982) </template>
										</CertificationBlock>
										<CertificationBlock :isCard="true" side="left" :hasNoBack="true">

											<template v-slot:logo>
												<img src="../assets/work_salesforce.svg" alt="Company Logo Salesforce" />
											</template>

											<template v-slot:title> Salesforce Service Cloud Consultant </template>

											<template v-slot:date> Aug 2016 (#9434020) </template>
										</CertificationBlock>
										<CertificationBlock :isCard="true" side="left" :hasNoBack="true">

											<template v-slot:logo>
												<img src="../assets/work_salesforce.svg" alt="Company Logo Salesforce" />
											</template>

											<template v-slot:title> Salesforce Developer </template>

											<template v-slot:date> Feb 2015 (#2338338) </template>
										</CertificationBlock>
										<CertificationBlock :isCard="true" side="left" :hasNoBack="true">

											<template v-slot:logo>
												<img src="../assets/work_salesforce.svg" alt="Company Logo Salesforce" />
											</template>

											<template v-slot:title> Salesforce Administrator </template>

											<template v-slot:date> Aug 2014 (#1999971) </template>
										</CertificationBlock>
										<CertificationBlock :isCard="true" side="left" :hasNoBack="true">

											<template v-slot:logo>
												<img src="../assets/company_sap.svg" width="100" alt="Company Logo SAP" />
											</template>

											<template v-slot:title> ABAP Workbench Foundations (BC400) </template>

											<template v-slot:date> Jul 2013 </template>
										</CertificationBlock>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section class="wrapper-section">
					<div>
						<span id="testimonials"></span>
						<div class="section-row">
							<div class="section-headline">
								<h2>What others say about me</h2>
							</div>
						</div>
					</div>
					<div>
						<div class="carousel" data-flickity='{ "wrapAround": true }' data-aos="fade-in" data-aos-offset="-700" data-aos-delay="50" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true" data-aos-anchor-placement="center-center">
							<div class="carousel-cell">
								<div class="testimonial">
									<div class="testimonial-text">
										Michael, as the CRM responsible manager of our customer Schaeffler, is always a <strong>pleasure to work with</strong>. He is <strong>dedicated, determined and always focused</strong> on our partnership designing and delivering new solutions for Schaeffler, bringing direction and focus to the strategy and architecture function. Michael is <strong>very knowledgeable and experienced</strong> in Salesforce and its products, especially Sales Cloud, and brings
										ideas and innovation to everything he does. He is <strong>eternally positive and his approach to all challenges is "can do"</strong>. Michael is able to engage his colleagues and other customers with his knowledge and passion for CRM, sales, marketing, and change management. Michael is a joy to work with and I would happily recommend him.
									</div>
									<hr class="testimonial-sep" />
									<img src="../assets/quote-left-solid.svg" height="64" class="testimonial-quote" alt="Icon quote" />
									<div class="testimonial-who-name">Angélique W.</div>
									<div class="testimonial-who-pos">Member of the Supervisory Board, Salesforce.com</div>
								</div>
							</div>
							<div class="carousel-cell">
								<div class="testimonial">
									<div class="testimonial-text">
										Michael is a <strong>most impressive</strong> guy to work with. His analytical mind, deep technical understanding combined with his people skills make him pretty <strong>unique</strong> and make it more than a <strong>pleasure to work with</strong> him as a team partner. He adds value to every project, because he sees solutions to difficulties, others haven't even started thinking about. We worked together in a challenging implementation project for 7
										months and Michael was for sure <strong>responsible for the success</strong> we achieved with our work.
									</div>
									<hr class="testimonial-sep" />
									<img src="../assets/quote-left-solid.svg" height="64" class="testimonial-quote" alt="Icon quote" />
									<div class="testimonial-who-name">Andreas R.</div>
									<div class="testimonial-who-pos">Consulting Director, Deloitte Digital</div>
								</div>
							</div>
							<div class="carousel-cell">
								<div class="testimonial">
									<div class="testimonial-text">
										<!-- TODO: "Praise" gmail ordner - unter "Various / Anonymous" angeben -->
										Michael [...] proved to be a <strong>very strong conceptual thinker and fast learner</strong>. He showed great understanding of the Force.com platform and is very knowledgeable about integration concepts in general. I would certainly recommend Michael to any company in the Force.com domain.
									</div>
									<hr class="testimonial-sep" />
									<img src="../assets/quote-left-solid.svg" height="64" class="testimonial-quote" alt="Icon quote" />
									<div class="testimonial-who-name">Harm K.</div>
									<div class="testimonial-who-pos">Principal Technical Consultant, CRMWaypoint</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<!-- Map is from OpenStreetMap, "Humanoid" style -> Now from http://maps.stamen.com/watercolor/#12/37.7706/-122.3782 -->
				<!-- Map from http://maps.stamen.com/watercolor/#12/47.4551/8.5316 -->
				<section class="wrapper-section section-odd map-scroll" style="padding: 0; z-index: 90">
					<div class="container-fluid map-inner">
						<div>
							<span id="contact"></span>
							<div class="section-row">
								<div class="section-headline contact-headline">
									<h2>Contact me</h2>
								</div>
							</div>
						</div>
						<!-- Sometimes not working. data-aos="fade-in" data-aos-offset="-600" data-aos-delay="50" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true" data-aos-anchor-placement="bottom-bottom" -->
						<div style="z-index: 99">
							<div class="section-row wrapper-contact">
								<div class="contact-left">
									<!--<address>
										<span class="mini-header">Address</span><br />
										<span class="n-obfuscate" data-firstName="Michael" data-lastName="Schmidt-Korth"></span><br />
										<span class="a-obfuscate" data-street="Ringstrasse" data-nr="23" data-zip="8057" data-city="Zurich"></span>
									</address>-->
									<address>
										<span class="mini-header">Email</span><br />
										<a href="javascript:void(0);" class="e-obfuscate" data-name="michael" data-domain="schmidt-korth" data-tld="de" onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"></a>
										<!-- JS plugin for obfuscation - works well, but sometimes the color is light grey and cannot be read after refresh (even outside of this div) -->
										<!-- <span style="opacity: 0.8;">
												<span id="e"></span>
											</span> -->
									</address>
									<address>
										<span class="mini-header">Threema</span><br />
										<a href="javascript:void(0);" class="t-obfuscate" data-id1="EUFF" data-id2="MDEA" onclick="window.open('https://threema.id/' + this.dataset.id1 + this.dataset.id2, '_blank');"></a>
									</address>
								</div>
								<div class="contact-right">
									<!-- We do not use "action" as it always redirects to the URL - instead we use JavaScript/Vue to submit the form -->
									<!-- https://stackoverflow.com/questions/48028718/using-event-modifier-prevent-in-vue-to-submit-form-without-redirection/48029830 -->
									<!-- https://stackoverflow.com/questions/25983603/how-to-submit-an-html-form-without-redirection -->
									<form id="contact-form" v-on:submit.prevent="submitContactRequest()">
										<!-- submit.prevent is shorthand for "on submit, do event.preventDefault() to stop the form from submitting". The entire submit.prevent="myFunction()" means "stop the form from submitting the normal way and execute myFunction() instead". If you want to submit the form the ordinary way, i.e. with a page refresh, this is NOT the way to do it. Otherwise, in myFunction() you need to be performing an ajax request and then redirecting once it's finished. -->
										<fieldset>
											<!-- Note: Escaping is done on server side - no need to do in Vue as we don't directly call a 3rd-party API -->
											<legend style="font-size: 1.5em; text-transform: uppercase">Get in touch</legend>
											<p style="margin-top: 0">
												<label for="form-name">Name</label>
												<input id="form-name" v-model="formName" type="text" name="name" placeholder="Name ..." minlength="2" required />
											</p>
											<p>
												<!-- Email is formatted by browser upon input, good enough for me! I don't care a lot if email is wrong. -->
												<label for="form-email">Email</label>
												<input id="form-email" v-model="formEmail" type="email" name="email" placeholder="Email ..." minlength="5" required />
											</p>
											<p>
												<label for="form-msg">Message</label>
												<textarea id="form-msg" v-model="formMessage" placeholder="Your Message ..." minlength="4" required></textarea>
											</p>
											<p>
												<button type="submit" name="message" value="Submit" style="text-transform: uppercase" :disabled="isDisabledForm"><font-awesome-icon :icon="['fas', 'paper-plane']" /> Reach out to me</button>
											</p>
											<!-- TODO: (prio 3) Recaptcha - Do not use for now, only if I should get spammed https://morioh.com/p/63eb00eaf509 https://developers.google.com/recaptcha/docs/invisible#explicit_render -->
											<!-- https://www.npmjs.com/package/vue-recaptcha-v3 -->
										</fieldset>
									</form>
								</div>
							</div>
						</div>
					</div>
				</section>
				<footer class="main-footer">
					<ul id="footer-buttons">
						<li class="footer-link">
							<a href="https://salesforce-automation.herokuapp.com/" target="_blank">Salesforce Automation Guide</a>
						</li>
						<li>
							<span style="font-size: 2em; color: #f0efef">|</span>
						</li>
						<li class="footer-link">
							<a class="nobox tooltip" target="_blank">
								Salesforce Sharing Designer
								<span class="tooltiptext tooltip-small" style="bottom: -70%"><img src="../assets/construction.svg" style="width: 1.5em; vertical-align: middle; margin-right: 0.5em" alt="Icon construction" />Work in Progress</span>
							</a>
						</li>
						<li>
							<span style="font-size: 2em; color: #f0efef">|</span>
						</li>
						<li class="footer-link">
							<a href="javascript:void(0);" @click="toggleModal()">Privacy Policy</a>
						</li>
					</ul>
					© 2020—{{ new Date().getFullYear() }} Michael Schmidt-Korth
					<br />
					All Rights Reserved. All product names, logos, and brands are property of their respective owners. All company, product and service names used in this website are for identification purposes only.
				</footer>
			</div>
		</div>
	</div>

	<!-- TODO: (prio 3) Server-side logging of any request, to track also side visits of users that denied cookie (no consent required; only visit is tracked without cookie) -->
	<div v-if="isShowCookie" class="cookie-consent">
		<div class="consent-text">Please allow me to use cookies to improve the usability of my website. Find more information in my <a href="javascript:void(0);" @click="toggleModal()">Privacy Policy</a>.</div>
		<div class="consent-buttons">
			<button type="button" @click="setConsent(true)" style="flex: 0 1 auto">Allow</button>
			<button type="button" @click="setConsent(false)" class="consent-decline" style="flex: 0">Decline</button>
		</div>
	</div>

	<transition name="fade">
		<div id="notify" :class="isAlertBad ? 'notify-bad' : 'notify-good'" v-if="isAlertShow" style="line-height: 1.5em">
			<i class="pi" :class="isAlertBad ? 'pi-exclamation-triangle' : 'pi-check-circle'" style="float: left; font-size: 1.5em; opacity: 0.8"></i>
			{{ alert }}
		</div>
	</transition>
</template>

<script>
import { ref, computed } from 'vue';
import { onMounted, onUpdated, onUnmounted } from 'vue';

import Tooltip from './Tooltip';
import TimelineBlock from './TimelineBlock';
import ProjectBlock from './ProjectBlock';
import Projects from './Projects';
import Skills from './Skills';
import CertificationBlock from './CertificationBlock';
import Modal from './Modal';
import TooltipAbbreviation from './TooltipAbbreviation';
import Flickity from 'flickity';

import qs from 'qs';
import Typed from 'typed.js'; // https://github.com/mattboldt/typed.js
import AOS from 'aos'; // Animate on Scroll (independent of Vue version) // https://github.com/michalsnik/aos
import '../../node_modules/aos/src/sass/aos.scss';
// import EmailObfuscate from 'email-obfuscate'; // https://github.com/dunckr/email-obfuscate

// Not needed - https://jonaskuske.github.io/smoothscroll-anchor-polyfill/ https://stackoverflow.com/questions/13735912/anchor-jumping-by-using-javascript http://iamdustan.com/smoothscroll/
// import { polyfill } from "seamless-scroll-polyfill"; // https://github.com/magic-akari/seamless-scroll-polyfill
// import { elementScrollIntoView } from "seamless-scroll-polyfill";

// const Modal = () => import('./Modal.vue'); // Lazy import / dynamic import, only when needed/component is triggered -> Somehow error - https://github.com/gangsthub/dynamic-imports-example/blob/master/pages/with.vue

AOS.init();

// Scroll markers
var isScrollWork = ref(false);
var isScrollProjects = ref(false);
var isScrollSkills = ref(false);
var isScrollCertifications = ref(false);
var isScrollTestimonials = ref(false);
var isScrollContact = ref(false);

var isShowMenu = ref(false);
var isShowModal = ref(false);
var isShowCookie = ref(true);

// Contact form
var formName = ref('');
var formEmail = ref('');
var formMessage = ref('');

var displayMode = 'desktop';

let isAlertBad = ref(false);
let isAlertShow = ref(false);
let alert = ref('');

let isDisabledForm = ref(false);

export default {
	name: 'CurriculumVitae',
	props: {},
	components: {
		Tooltip,
		TimelineBlock,
		ProjectBlock,
		CertificationBlock,
		Modal, //: () => import ('./Modal'), // Lazy import / dynamic import, only when needed/component is triggered -> Somehow error - https://github.com/gangsthub/dynamic-imports-example/blob/master/pages/with.vue
		TooltipAbbreviation,
		Projects,
		Skills,
	},
	methods: {
		toggleMenu: function () {
			isShowMenu.value = !isShowMenu.value;
		},
		downloadCV: function () {
			// Note: Link is defined as a HTTP redirect in vercel.json
			window.open(window.location.origin + '/cv', '_blank');
		},
		// Call serverless function hosted on Vercel
		submitContactRequest() {
			let vueThis = this; // Backup
			let isSuccess = true;
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, // Header is required to avoid CORS error - https://github.com/axios/axios/issues/858
				body: qs.stringify({ name: formName.value, email: formEmail.value, message: formMessage.value }),
			};
			fetch('https://cv-server.vercel.app/api/server', requestOptions)
				.then(async (response) => {
					const data = await response.json();

					if (!response.ok) {
						const error = (data && data.message) || response.status;

						return Promise.reject(error);
					}

					this.postId = data.id;
				})
				.catch((error) => {
					this.errorMessage = error;
					isSuccess = false;
					console.error('Could not send contact request.', error);
				})
				.then(function () {
					if (isSuccess) {
						vueThis.notify('Thank you for your message.', isSuccess);
						isDisabledForm.value = true;
					} else {
						vueThis.notify('Oops, unable to send a message - please contact me via email, LinkedIn or Xing.', isSuccess);
						isDisabledForm.value = true;
					}
				});

			// Above code is async, beware - anything below just continues in parallel
		},
		/* Displays alert toast. */
		notify(msg, success = false) {
			isAlertBad.value = !success;
			isAlertShow.value = true;

			alert.value = msg;

			setTimeout(() => {
				isAlertShow.value = false;
			}, 2000);
		},
		toggleModal() {
			isShowModal.value = !isShowModal.value;
		},
		setConsent(choice) {
			this.$gtm.enable(choice);
			isShowCookie.value = false;

			// We explicitly do not set a cookie if user declined, to show banner again on refresh
			if (choice) {
				// Doesn't work on localhost as no SSL
				let isSecure = window.location.origin.includes('localhost') ? false : true;

				this.$cookie.setCookie('msk-consent', 'true', {
					expire: '365d',
					path: '/',
					domain: '',
					secure: isSecure,
					sameSite: 'Strict',
				});
			}
		},
		responsiveBlocks() {
			// Allows fine-grained control on what to display in which column for which device
			if (displayMode == 'desktop' && window.innerWidth <= 1250) {
				console.log('Desktop -> Phone');
				displayMode = 'phone';
				this.projectBlocks1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
				this.projectBlocks2 = [];

				this.skillBlocks1 = [1, 2, 3, 4, 5, 6, 7];
				this.skillBlocks2 = [];
			} else if ((displayMode == 'phone' && window.innerWidth > 1250) || displayMode == 'desktop') {
				console.log('Phone -> Desktop');
				displayMode = 'desktop';
				// Even vs Odd
				this.projectBlocks1 = [1, 3, 5, 7, 9];
				this.projectBlocks2 = [2, 4, 6, 8, 10];
				// Even vs Odd but keeping both languages together
				this.skillBlocks1 = [1, 3, 5];
				this.skillBlocks2 = [2, 4, 6, 7];
			}

			// Below solution is using vanilla JS to do the same vby "moving" HTML blocks via appendChild() etc.
			// This works, BUT: Vue will not know that you moved the components, and as such any @click methods etc. will no longer work https://stackoverflow.com/questions/59701547/vue-js-equivalent-of-appendchild-to-dynamically-add-new-element-component
			// Lessons learned: Never use vanilla JS to modify the DOM! Vue has its own DOM, the virtual DOM. It does not know if you modify the standard DOM, which will lead to issues.

			// 	// Condition so we only calculate once on threshold, not on every pixel change
			// 	// Switch to phone mode
			// 	displayMode = 'phone';
			// 	console.log('MODE: Switching from Desktop to Phone');

			// 	// Get all items from both columns (desktop), and merge them into a single element (for phone)
			// 	let allProjectColumnItems = {};
			// 	let nodesFragment = document.createDocumentFragment();
			// 	let j = 0;
			// 	document.querySelectorAll('.projects-left > .projects-item').forEach(function(projectColumnItem, i) {
			// 		j = i * 2; // Even values
			// 		allProjectColumnItems[j] = projectColumnItem;
			// 	});

			// 	document.querySelectorAll('.projects-right > .projects-item').forEach(function(projectColumnItem, i) {
			// 		j = i * 2 + 1; // Odd values
			// 		allProjectColumnItems[j] = projectColumnItem;
			// 	});

			// 	// Add elements from both columns into single element - one from even, one from odd, ... to keep the same order
			// 	for (let [key, element] of Object.entries(allProjectColumnItems)) {
			// 		nodesFragment.appendChild(element);
			// 	}
			// 	document.getElementById('project-wrapper').appendChild(nodesFragment);
			// 	// }
			// } else if (displayMode == 'phone' && window.innerWidth > 1250) {
			// 	// Restore two-column
			// 	displayMode = 'desktop';
			// 	console.log('MODE: Switching from Phone to Desktop');

			// 	// Remove single column
			// 	let node = document.getElementById('project-wrapper');
			// 	while (node.firstChild) {
			// 		node.removeChild(node.firstChild);
			// 	}

			// 	// Add two column
			// 	// Cloning, as childNodes will live-update; so after adding the first one the second one would be null
			// 	let column1 = backupWide.childNodes[0].cloneNode(true);
			// 	let column2 = backupWide.childNodes[1].cloneNode(true);
			// 	document.getElementById('project-wrapper').appendChild(column1);
			// 	document.getElementById('project-wrapper').appendChild(column2);
			// }
		},
	},
	watch: {},
	data() {
		return {
			// Projects
			projectBlocks1: [],
			projectBlocks2: [],
			projectsCmp: 'Projects', // Identifier: Component Name
			// Skills
			skillBlocks1: [],
			skillBlocks2: [],
			skillsCmp: 'Skills',
		};
	},
	// computed: {
	// currentProperties: function() {
	// 	if (this.currentComponent === 'myComponent') {
	// 	return { foo: 'bar' }
	// 	}
	// },
	computed: {},
	setup() {
		return {
			isShowMenu,
			isScrollWork,
			isScrollProjects,
			isScrollSkills,
			isScrollCertifications,
			isScrollTestimonials,
			isScrollContact,
			isShowModal,
			isShowCookie,
			isAlertBad,
			isAlertShow,
			isDisabledForm,
			alert,
			formName,
			formEmail,
			formMessage,
		};
	},
	mounted() {
		this.responsiveBlocks(); // Initial load
		window.addEventListener('resize', this.responsiveBlocks);

		// Consent cookie
		isShowCookie.value = !this.$cookie.getCookie('msk-consent');

		// On mount, otherwise querySelector will fail
		// Carousel
		// setTimeout(() => {
		// 	var elem = document.querySelector('.main-carousel');
		// 	var flkty = new Flickity(elem, {
		// 		cellAlign: 'left',
		// 		contain: true,
		// 	});
		// }, 500);

		// Typed.js
		var options = {
			strings: ['Enthusiast', 'Director', 'Architect', 'Developer', 'Admin', 'Project Manager', 'Advisor'],
			typeSpeed: 60,
			backDelay: 1600,
			backSpeed: 60,
			startDelay: 1000,
			loop: true,
			loopCount: Infinity,
		};
		var typed = new Typed('.attributes', options);

		// Email obfuscation
		// var el = document.getElementById('e');

		// EmailObfuscate(el, {
		// 	// Email construct: name@domain.tld
		// 	name: 'michael',
		// 	domain: 'schmidt-korth',
		// 	tld: 'de',
		// 	// Alternate Text
		// 	altText: 'Email'
		// });

		// polyfill();

		// elementScrollIntoView(document.querySelector('#work'), { behavior: 'smooth', block: 'center', inline: 'center' });

		// kick off the polyfill!
		// smoothscroll.polyfill();

		// Setup isScrolling variable
		var isScrolling;
		window.addEventListener('scroll', function () {
			// Clear our timeout throughout the scroll
			window.clearTimeout(isScrolling);

			// Set a timeout to run after scrolling ends, so menu is not highlighted *during* scrolling (when clicking menu and jumping between items)
			isScrolling = setTimeout(function () {
				var work = document.getElementById('work');
				var projects = document.getElementById('projects');
				var skills = document.getElementById('skills');
				var certifications = document.getElementById('certificationsM');
				var testimonials = document.getElementById('testimonials');
				var contact = document.getElementById('contact');

				// Scrolled to Id, but not past (&&)?
				//   Add buffer of 200 to allow scrollIntoView() to trigger as well - i.e. behave as if the user scrolled a bit further
				isScrollWork.value = window.scrollY + 150 > work.offsetTop + work.offsetHeight && window.scrollY < projects.offsetTop;
				if (isScrollWork.value) (isScrollProjects.value = false), (isScrollSkills.value = false), (isScrollCertifications.value = false), (isScrollTestimonials.value = false), (isScrollContact.value = false);
				// Set others to false - works without, but there are edge cases where two or more items might be highlighted as all are on screen, that is confusing
				isScrollProjects.value = window.scrollY + 150 > projects.offsetTop + projects.offsetHeight && window.scrollY < skills.offsetTop;
				if (isScrollProjects.value) (isScrollWork.value = false), (isScrollSkills.value = false), (isScrollCertifications.value = false), (isScrollTestimonials.value = false), (isScrollContact.value = false);
				isScrollSkills.value = window.scrollY + 150 > skills.offsetTop + skills.offsetHeight && window.scrollY < certifications.offsetTop;
				if (isScrollSkills.value) (isScrollProjects.value = false), (isScrollWork.value = false), (isScrollCertifications.value = false), (isScrollTestimonials.value = false), (isScrollContact.value = false);
				isScrollCertifications.value = window.scrollY + 150 > certifications.offsetTop + certifications.offsetHeight && window.scrollY < testimonials.offsetTop;
				if (isScrollCertifications.value) (isScrollProjects.value = false), (isScrollSkills.value = false), (isScrollWork.value = false), (isScrollTestimonials.value = false), (isScrollContact.value = false);

				var isAtBottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
				isScrollTestimonials.value = window.scrollY + 200 > testimonials.offsetTop + testimonials.offsetHeight && !isAtBottom; // && window.scrollY < contact.offsetTop;
				// isScrollTestimonials.value = window.scrollY - 800 > testimonials.offsetTop + testimonials.offsetHeight;
				if (isScrollTestimonials.value) (isScrollProjects.value = false), (isScrollSkills.value = false), (isScrollCertifications.value = false), (isScrollWork.value = false), (isScrollContact.value = false);
				console.log(window.scrollY, testimonials.offsetTop, testimonials.offsetHeight, contact.offsetTop);
				//   No need to highlight Contact, as testimonial will always be visible
				if (isAtBottom) isScrollContact.value = true;
				//   isScrollContact.value = (window.scrollY > (contact.offsetTop + contact.offsetHeight))
				// isScrollContact.value = window.scrollY + -100 > testimonials.offsetTop + testimonials.offsetHeight;
				// if (isScrollContact.value) (isScrollProjects.value = false), (isScrollSkills.value = false), (isScrollCertifications.value = false), (isScrollWork.value = false), (isScrollTestimonials.value = false);
			}, 66);
		});
	},
};
</script>

<style scoped></style>

<style>
@import '../../node_modules/flickity/dist/flickity.min.css';
@import '../..//node_modules/@fortawesome/fontawesome-svg-core/styles.css';
</style>

<style lang="scss">
@import '../assets/css/main.scss';
</style>