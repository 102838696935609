<template>
	<a class="nobox tooltip help" target="_blank"
		>{{ shortVal }}<span class="tooltiptext tooltip-wide tooltip-abbr" style="bottom: -70%">{{ longVal }}</span></a
	>
</template>

<script>
	export default {
		name: 'TooltipAbbreviation',
		props: {
			short: String,
			long: String,
		},
		components: {},
		methods: {},
		data() {
			return {
				shortVal: this.short,
				longVal: this.long,
			};
		},
		mounted() {},
		setup() {},
	};
</script>
